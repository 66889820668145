import { ComponentType } from "react";
import AnnouncementFilterContextProvider from "src/contexts/AnnouncementFilterContext";

/* eslint-disable react/display-name */
const withAnnouncementFilterContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <AnnouncementFilterContextProvider>
        <Component {...props} />
      </AnnouncementFilterContextProvider>
    );
  };

export default withAnnouncementFilterContext;
