import useUserAccount from "src/store/userAccountState";
import axios from "src/services/network/axios";

import { useQuery, QueryFunction } from "react-query";
import chainGet from "src/services/network/chainGet";

import { StringOrNull, PaginationQueryType } from "src/types";

export type QueryKeysType = [string | null];

export type FetcherParamType = {
  queryKey: QueryKeysType;
};

export type CompaniesData = {
  accountNumber: StringOrNull;
  accountType: StringOrNull;
  id: string;

  status: StringOrNull;
  phone: StringOrNull;
  name: string;
  minutesUsedSoFar: number;
  minutesRemaining: number;
  chatsUsedSoFar: number;
  chatsRemaining: number;
  chatAllowance: number | null;
  callAllowance: number | null;
};

export type CompaniesDataArray = Array<CompaniesData>;

type GetCompaniesType = {
  data: Array<CompaniesData>;
  pagination: PaginationQueryType;
};

// {{baseUrl}}/businessAccounts/a2C590000004mrQEAQ/companies?type=&page=1&limit=50&query=&sortBy=&sortType=

const fetchCompanies: QueryFunction<GetCompaniesType, QueryKeysType> = async ({
  queryKey,
}: FetcherParamType) => {
  const businessAccountId = queryKey[0];

  const response = await axios.get(
    `/businessAccounts/${businessAccountId}/companies?type=&page=1&limit=50&query=&sortBy=&sortType=DESC`
  );

  return response?.data;
};

const useCompaniesData = () => {
  const { userAccount } = useUserAccount();

  const businessAccountId: StringOrNull = chainGet(
    userAccount!.businessAccountId,
    null
  );

  const query = useQuery({
    queryKey: [businessAccountId],
    queryFn: fetchCompanies,
    enabled: !!businessAccountId,
  });

  return query;
};

export default useCompaniesData;

// **SAMPLE RESPONSE**
// const foo = [
//   {
//     status: "Current",
//     phone: "7022222222",
//     name: "Hamilton's Racing Cars - Stage 5",
//     minutesUsedSoFar: 56,
//     minutesRemaining: 0,
//     id: "00177000009v7nqAAA",
//     chatsUsedSoFar: 5,
//     chatsRemaining: 0,
//     chatAllowance: null,
//     callAllowance: null,
//     accountType: "Abby Connect",
//     accountNumber: "234020",
//   },
//   {
//     status: "Current",
//     phone: "8773035757",
//     name: "Hamilton's Fashion Co",
//     minutesUsedSoFar: null,
//     minutesRemaining: 0,
//     id: "00177000009wM8YAAU",
//     chatsUsedSoFar: null,
//     chatsRemaining: 0,
//     chatAllowance: null,
//     callAllowance: null,
//     accountType: "Abby Connect",
//     accountNumber: "237069",
//   },
// ];
