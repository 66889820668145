import { useMemo, FC } from "react";
import createContextSet from "src/utils/createContextSet";
import { useForm, UseFormSetValue } from "react-hook-form";
import notNull from "src/utils/notNull";

export const templatesFilterTypes = {
  QUERY: "query",
  TAKING_CALLS: "isTakingCalls",
} as const;
import useSorting from "src/hooks/useSorting";
import { SortEnum } from "src/hooks/queries/activities/activitiesKeys";
import { IUseSortingOption } from "src/hooks/useSorting";

export type FilterTypeArgs =
  typeof templatesFilterTypes[keyof typeof templatesFilterTypes];

// add other filter fields as needed
// export type StatusTemplatesFilterType = {
//   queryFilter: string;
//   isTakingCalls: boolean | null;
// };

export type StatusTemplatesContextReturnType = {
  setValue: UseFormSetValue<{
    queryFilter: string;
    isTakingCallsFilter: boolean | null;
  }>;
  //   --
  queryFilter: string;
  isTakingCallsFilter: boolean | null;
  clearFilterByType: (filterType: FilterTypeArgs) => void;
  clearAllFilters: () => void;
  hasActiveFilter: boolean;
  sortState: IUseSortingOption;
};

const [useStatusTemplatesFilterCtx, StatusTemplateFilterCtxProvider] =
  createContextSet<StatusTemplatesContextReturnType>();

const initialValues = {
  queryFilter: "",
  isTakingCallsFilter: null as boolean | null,
};

const initialSortState = {
  sortBy: "createdDate",
  sortType: SortEnum.DESC,
};

const StatusTemplateFilterContextProvider: FC = ({ children }) => {
  const sortState = useSorting(initialSortState);

  const hookFormVals = useForm({
    defaultValues: initialValues,
  });

  const { watch, setValue, reset } = hookFormVals;
  const queryFilter = watch("queryFilter");
  const isTakingCallsFilter = watch("isTakingCallsFilter");

  const clearFilterByType = (filterType: FilterTypeArgs) => {
    if (filterType === templatesFilterTypes.QUERY) {
      setValue("queryFilter", "");
    }
    if (filterType === templatesFilterTypes.TAKING_CALLS) {
      setValue("isTakingCallsFilter", null);
    }
  };

  const clearAllFilters = () => {
    reset();
  };

  const hasActiveFilter =
    !![queryFilter].find((item) => Boolean(item)) ||
    notNull(isTakingCallsFilter);

  const contextValue = useMemo(
    () => ({
      setValue,
      queryFilter,
      isTakingCallsFilter,
      clearFilterByType,
      hasActiveFilter,
      clearAllFilters,
      sortState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, queryFilter, isTakingCallsFilter, sortState]
  );

  return (
    <StatusTemplateFilterCtxProvider value={contextValue}>
      {children}
    </StatusTemplateFilterCtxProvider>
  );
};

export default StatusTemplateFilterContextProvider;
export const useStatusTemplateFilterContext = useStatusTemplatesFilterCtx;
