import { ComponentType } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterSpinner from "src/components/shared/CenterSpinner";
import useIsBetaOrigin from "src/hooks/useIsBetaOrigin";

/* eslint-disable react/display-name */
const withIsBetaGuard =
  (Component: ComponentType) => (props: Record<string, any>) => {
    const isBetaOrigin = useIsBetaOrigin();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isBetaOrigin) {
        return navigate("/dashboard");
      }
    }, [isBetaOrigin, navigate]);

    return !isBetaOrigin ? (
      <Flex h="100vh" justify="center" align="center">
        <Text mr={4}> Redirecting Please Wait...</Text>
        <CenterSpinner />
      </Flex>
    ) : (
      <Component {...props} />
    );
  };

export default withIsBetaGuard;
