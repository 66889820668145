import dayjs from "dayjs";

const parseDate = (dateString: string | null): Date | null => {
  const checkedDate = dayjs(dateString);
  if (
    checkedDate.isValid() &&
    checkedDate.isAfter("1999-12-31") &&
    checkedDate.isBefore("3000-01-01")
  ) {
    const [, day] = dateString ? dateString.split("/").map(Number) : [];
    if (checkedDate.date() === day) {
      return checkedDate.toDate();
    }
  }

  return null;
};
export default parseDate;
