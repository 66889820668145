/**
 * accepts any value but I will use it mostly is on accessing object paths  with optional chaining
 * returns fallback if value is falsy
 * @param value - Accepts any value. - but mostly for data using optional chaining (e.g data?.item?.text)
 * @param fallbackValue - returned if value argument is "falsy" - if not provided default is null.
 */

const chainGet = <T>(
  value: T,
  fallbackValue: any = null
): Exclude<T, undefined> => {
  return value || fallbackValue;
};

export default chainGet;
