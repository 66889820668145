import { Flex, useMediaQuery } from "@chakra-ui/react";
import { useCompanySelection } from "../../store/companySelectionState";
import useUserAccount from "../../store/userAccountState";
import LoginAsAlertBg from "../../assets/LoginAsAlertBg.svg";
import breakpoints from "../../constants/breakpoints";
import React from "react";
import useLogout from "../../hooks/useLogout";

const LoginAsAlert: React.FC = () => {
  const { userAccount } = useUserAccount();

  const [isBelowMd] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  const { activeCompanyObject } = useCompanySelection();

  const onLogout = useLogout();

  return (
    <Flex
      style={{
        background: `url(${LoginAsAlertBg}), #54698D`,
        color: "#FFF",
        ...(isBelowMd
          ? {
              flexDirection: "column",
              fontSize: "10px",
              padding: "7px",
            }
          : {
              flexDirection: "row",
            }),
      }}
      height={["65px", "60px"]}
    >
      <Flex
        style={{
          ...(isBelowMd
            ? {
                textAlign: "center",
                width: "100%",
                justifyContent: "center",
              }
            : {
                alignItems: "center",
                flexBasis: "70%",
                justifyContent: "flex-end",
              }),
        }}
      >
        <Flex px={"20px"}>
          <span>
            You are logged in as <b>{userAccount?.username}</b> (username:{" "}
            <b>{userAccount?.username}</b>){" "}
            {activeCompanyObject?.name ? (
              <>
                under <b>{activeCompanyObject?.name}</b>
              </>
            ) : (
              ""
            )}
          </span>
        </Flex>
      </Flex>
      <Flex
        style={{
          ...(isBelowMd
            ? {
                textAlign: "center",
                width: "100%",
                justifyContent: "center",
                marginTop: "5px",
              }
            : {
                alignItems: "center",
                flexBasis: "30%",
                justifyContent: "flex-start",
              }),
        }}
      >
        <Flex
          px={"20px"}
          style={{
            ...(isBelowMd
              ? {}
              : {
                  borderLeft: "1px solid #8099D0",
                }),
          }}
        >
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              ...(isBelowMd ? {} : {}),
            }}
            onClick={() => {
              onLogout();
            }}
          >
            Log out as {userAccount?.username}
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginAsAlert;
