import React, { FC, useState } from "react";
import createContextSet from "src/utils/createContextSet";

export type AnnouncementFilterReturnType = {
  hasActiveFilter: boolean;
  readStatusFilter: boolean | null;
  setReadStatusFilter: React.Dispatch<React.SetStateAction<boolean | null>>;
};

const [useAnnouncementFilterCtx, AnnouncementFilterContext] =
  createContextSet<AnnouncementFilterReturnType>();

const AnnouncementFilterContextProvider: FC = ({ children }) => {
  const [readStatusFilter, setReadStatusFilter] = useState<boolean | null>(
    null
  );

  const hasActiveFilter = typeof readStatusFilter === "boolean";

  const contextValue = {
    hasActiveFilter,
    readStatusFilter,
    setReadStatusFilter,
  };

  return (
    <AnnouncementFilterContext value={contextValue}>
      {children}
    </AnnouncementFilterContext>
  );
};

export default AnnouncementFilterContextProvider;
export const useAnnouncementFilterContext = () => useAnnouncementFilterCtx();
