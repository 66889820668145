import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Divider,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import useUserAgreement from "src/hooks/mutations/useUserAgreement";
import useGetUserQuery from "src/hooks/queries/settings/useGetUserQuery/useGetUserQuery";
import useIsBetaOrigin from "src/hooks/useIsBetaOrigin";
import { useLoggedInAs } from "src/store/loggedInAs";

const BetaTermsPopup = () => {
  const isBetaOrigin = useIsBetaOrigin();
  const { data: userData, isLoading: isUserDataLoading } = useGetUserQuery();

  const { mutate: mutateSignAgreement } = useUserAgreement();
  const { isAdminLoggedInAsUser, isAuthenticating } = useLoggedInAs();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      !isUserDataLoading &&
      userData &&
      !userData?.betaAcceptedTerms &&
      isBetaOrigin &&
      !isAdminLoggedInAsUser &&
      !isAuthenticating
    ) {
      onOpen();
    }
  }, [userData, isBetaOrigin, isAdminLoggedInAsUser, isUserDataLoading]);

  const handleUserAgreement = () => {
    mutateSignAgreement({ betaAcceptedTerms: true });
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => null}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent borderRadius="10px">
          <ModalHeader color="white" borderRadius="10px" bg="#A6B5E2">
            Abby Connect Beta Terms & Conditions
          </ModalHeader>
          {/* <ModalCloseButton /> */}

          <Divider />
          <ModalBody>
            <Text mb="5" fontSize="sm">
              As the Beta Product is still in a testing phase, it is likely to
              contain errors. In addition to the disclaimers in the Online
              Terms, you agree that the Beta Product is provided "AS IS" and "AS
              AVAILABLE." You understand and agree that we may change, withdraw,
              terminate your access to, testing of and/or use of, or discontinue
              the Beta Product (or any portion thereof) at any time and in our
              sole discretion, with or without notice to you. During the term of
              these Testing Terms, you will use commercially reasonable efforts
              to provide suggestions, comments or ideas and report issues or
              problems related to your use of the Beta Product (collectively,
              "feedback") to us in a timely basis or as otherwise agreed between
              the parties in writing (email will suffice). You agree not to
              disclose feedback to any third party and hereby assign to us all
              right, title and interest in and to any feedback, without any
              right to compensation or other obligation from us.
            </Text>
          </ModalBody>
          <Divider />

          <ModalFooter justifyContent="flex-start">
            <Button
              px={8}
              _hover={{
                bg: "#E3EBFC",
                color: "#000",
              }}
              fontSize="12px"
              borderRadius="35px"
              bg="linear-gradient(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)"
              textColor="#fff"
              mr={2}
              onClick={handleUserAgreement}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BetaTermsPopup;
