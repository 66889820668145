// import { format } from "date-fns";
import { useEffect } from "react";
import { useCallback, useState } from "react";
import {
  AccountInformationType,
  useAccountSettingState,
} from "src/store/accountSettingState";
import dayjs from "src/utils/dayjs";
import TimeZone from "src/utils/timezone";

const useTimeZone = () => {
  const [timeZone, setTimeZone] = useState<typeof TimeZone[number]>();
  const { accountInformation } = useAccountSettingState();

  let { timeZone: timeZone_ } =
    accountInformation || ({} as AccountInformationType);

  if (timeZone_ === "(GMT-07:00) Arizona") {
    // GMT-07:00 isn't recognized by dayjs
    timeZone_ = "(UTC-07:00) Arizona";
  }

  const getTimeZone = useCallback(() => {
    if (timeZone_) {
      const timeZoneObj = TimeZone.find((tz) => tz.text === timeZone_);
      setTimeZone(timeZoneObj);
    }
  }, [timeZone_]);

  const getTimezoneDate = (
    date: string | null | undefined,
    format: string | undefined = "MM/DD/YYYY LT"
  ) => {
    if (!date) return null;
    let timeZoned;
    if (timeZone && timeZone.utc) {
      timeZoned = dayjs.tz(dayjs(date), timeZone?.utc[0]).format(format);
    } else {
      timeZoned = dayjs(date).format(format);
    }
    return timeZoned;
  };

  useEffect(() => {
    getTimeZone();
  }, [getTimeZone]);

  return getTimezoneDate;
};
export default useTimeZone;
