import axios from "src/services/network/axios";
import getTakeCalls from "src/utils/getTakeCalls";

export const updateStatus = (
  companyId: string,
  contactId: string,
  status: string | null,
  expireAtUTC: string | null = null,
  transferTo: string | null = null,
  connectOnly: string | null = null,
  doNotConnect: string | null = null,
  currentUnavailabilityExplanation: string | null = null,
  currentUnavailabilityExplanationOther: string | null = null,
  currentStatusNote: string | null = null,
  callStatusTemplateId: string | null = null
) => {
  const payload: any = {
    takeCalls: getTakeCalls(status || "Offline"),
    revertStatusOn: expireAtUTC ? expireAtUTC : null,
    transferPhone: transferTo ? transferTo : null,
    connectOnly: connectOnly || null,
    doNotConnect: doNotConnect || null,
    unavailabilityExplanation: currentUnavailabilityExplanation || null,
    statusNote: currentStatusNote || null,
    callStatusTemplateId: callStatusTemplateId || null,
  };
  if (currentUnavailabilityExplanation === "Other") {
    payload.unavailabilityExplanationOther =
      currentUnavailabilityExplanationOther?.trim() || null;
  }

  return axios.patch(
    `/companies/${companyId}/contacts/${contactId}/callStatus`,
    payload
  );
};

export const getContact = (companyId: string, contactId: string) => {
  return axios.get(`/companies/${companyId}/contacts/${contactId}`);
};
