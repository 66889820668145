const baseUrl = window.location.origin;
const betaUrl =
  (process.env.REACT_APP_BETA_BASE_URL || "").replace(/\/+$/, "") ||
  "https://beta.abby.com";

const useIsBetaOrigin = () => {
  return baseUrl.includes(betaUrl);
};

export default useIsBetaOrigin;
