import { useMemo } from "react";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

export type SearchHistoryPayloadType = string;

export type SearchHistoryStateType = {
  searchHistory: Array<string>;
};

const initialState: SearchHistoryStateType = {
  searchHistory: [],
};

// State slice and reducers
export const searchHistorySlice = createSlice({
  name: "searchHistoryState",
  initialState,
  reducers: {
    addSearchHistoryAction: (
      state,
      { payload }: PayloadAction<SearchHistoryPayloadType>
    ) => {
      if (payload?.length > 2) {
        if (state.searchHistory.length >= 1000) {
          state.searchHistory.pop();
          state.searchHistory.unshift(payload);
          state.searchHistory = [...new Set(state.searchHistory)];
          return;
        }
        state.searchHistory.unshift(payload);
        state.searchHistory = [...new Set(state.searchHistory)];
      }
    },
    clearSearchHistory: (state) => {
      state.searchHistory = [];
    },
  },
});

// Selectors
const selectSearchHistory = createSelector(
  (state: any) => {
    return state.searchHistoryState;
  },
  (searchHistoryState: SearchHistoryStateType) =>
    searchHistoryState.searchHistory
);

export const searchHistoryReducer = searchHistorySlice.reducer;

export const useSearchHistory = () => {
  const dispatch = useDispatch();
  const actions = searchHistorySlice.actions;
  const { addSearchHistoryAction } = actions;

  const addSearchHistory = (searchString: SearchHistoryPayloadType) =>
    dispatch(addSearchHistoryAction(searchString));

  const searchHistory = useSelector(selectSearchHistory);

  const searchHistoryReduxState = useMemo(
    () => ({
      actions,
      addSearchHistory,
      searchHistory,
    }),
    [searchHistory.length]
  );
  return searchHistoryReduxState;
};

export default useSearchHistory;
