import React from "react";
import CenterSpinner from "src/components/shared/CenterSpinner";

type Props = Record<string, any>;

// I Only use for child components that have Parent <Suspense>
//  to avoid screen flashing when changing routes

const withSuspense =
  (WrappedComponent: React.ComponentType, fallbackComponent = null) =>
  // eslint-disable-next-line react/display-name
  (props: Props) => {
    return (
      <React.Suspense fallback={fallbackComponent || <CenterSpinner />}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  };

export default withSuspense;
