import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-table";

export interface InvoiceStateType {
  selectedRows: Row<any>[] | null;
  enableExport: boolean;
  loading: boolean;
  error: string | undefined;
}
const initialState: InvoiceStateType = {
  selectedRows: [],
  enableExport: false,
  loading: false,
  error: undefined,
};

export const invoiceStateSlice = createSlice({
  name: "invoiceState",
  initialState,
  reducers: {
    setSelectedRowsAction: (
      state,
      { payload }: PayloadAction<{ selectedRows: Row<any>[] }>
    ) => {
      if (payload.selectedRows.length > 0) {
        state.selectedRows = payload.selectedRows;
        state.enableExport = true;
      } else {
        state.enableExport = false;
        if (
          state.selectedRows !== undefined &&
          state.selectedRows?.length !== 0
        ) {
          state.selectedRows = [];
        }
      }
    },
  },
});
export const invoiceSelector = (state: any) => state.invoiceState;

const setSelectedRow = createSelector(
  (state: any) => {
    return state.invoiceState;
  },
  (invoiceState: InvoiceStateType) => invoiceState.selectedRows
);

const enablExport = createSelector(
  (state: any) => {
    return state.invoiceState;
  },
  (invoiceState: InvoiceStateType) => invoiceState.enableExport
);

export const useInvoiceState = () => {
  const dispatch = useDispatch();
  const actions = invoiceStateSlice.actions;
  const { setSelectedRowsAction } = actions;
  const selectedRows = useSelector(setSelectedRow);

  const setSelectedRows = useCallback(
    (newSelectedRows: Row<any>[]) => {
      if (selectedRows?.length === newSelectedRows?.length) return null;

      dispatch(setSelectedRowsAction({ selectedRows: newSelectedRows }));
    },
    [selectedRows, dispatch, setSelectedRowsAction]
  );

  const getEnableExport = useSelector(enablExport);
  return {
    actions,
    selectedRows,
    setSelectedRows,
    getEnableExport,
  };
};
export const invoiceStateReducer = invoiceStateSlice.reducer;
