import { statusLabels } from "src/components/shared/navigationMenu/Header/StatusMenu";
import { ValueOf } from "src/types";

const getTakeCalls = (status: ValueOf<typeof statusLabels>) => {
  if (status === statusLabels["EMERGENCY_CALLS_ONLY"]) return status;

  return status === "Online" ? "Yes" : "No";
};

export default getTakeCalls;
