import axios from "src/services/network/axios";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import useUserAccount from "src/store/userAccountState";
import { useQueryClient } from "react-query";
import { useLoggedInAs } from "../../store/loggedInAs";

export const logoutUser = (userId: string) => async () => {
  const body = {
    userId,
  };
  return await axios.post(`/auth/logout`, body);
};

const useLogoutMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const { userAccount } = useUserAccount();
  const userId = userAccount?.id as string;
  const { setToken, setImpersonatedBy } = useLoggedInAs();

  return useMutation(logoutUser(userId), {
    onError() {
      toast({
        title: "Logout Error",
        description: "Logout Failed.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
    onSettled() {
      localStorage.removeItem("persist:root");
      setToken(null);
      setImpersonatedBy(null);
      localStorage.removeItem("entry-point-uri");
      queryClient.clear();
      navigate("/login");
    },
  });
};

export default useLogoutMutation;
