import { reject, isNil, anyPass, type } from "ramda";

type NonUndefined<T> = Exclude<T, undefined>;

// utility type that validates an object where null or undefined are removed
export type RemovedNilType<T> = Pick<
  T,
  {
    [Prop in keyof T]: NonNullable<Prop>;
  }[keyof T]
>;

type RecordType = Record<string, any>;

const removeNulls = <T>(obj: T) => {
  if (type(obj) !== "Object") {
    throw new Error("argument must be an Object Type");
  }

  return reject(anyPass([isNil]))(obj as RecordType) as RemovedNilType<T>;
};

export default removeNulls;
