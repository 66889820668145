import entities from "src/constants/entities";
import { QueryKeyType } from "../paginationQuery/useDataWithPagination";
import ActivityQueryTypes from "src/constants/activityQueryTypes";

export enum SortEnum {
  ASC = "ASC",
  DESC = "DESC",
}
export interface ActivitySortOptions {
  sortBy: string;
  sortType: SortEnum;
}

export type ActivitiesOptions = {
  companyId: string | null;
  type: string;
  sort?: ActivitySortOptions;
  filters: {
    query: string;
    startDate: number | null; //  timestamp
    endDate: number | null; // timestamp
    type: ActivityQueryTypes | null;
    readStatus: boolean | null;
    isArchived?: boolean;
    hasSummary?: boolean;
  };
};

export interface ActivitiesBadgeOption {
  companyId: string | null;
}

type ActivityKeysType = {
  activitiesList: (
    options: ActivitiesOptions
  ) => QueryKeyType<ActivitiesOptions>;
  activitiesBadge: (
    options: ActivitiesBadgeOption
  ) => QueryKeyType<ActivitiesBadgeOption>;
  exportActivity: (
    options: ActivitiesBadgeOption
  ) => QueryKeyType<ActivitiesBadgeOption>;
};

export const activitiesKeys: ActivityKeysType = {
  activitiesList: (options: ActivitiesOptions) => [
    entities.ACTIVITIES,
    {
      companyId: options.companyId,
      type: options.type,
      sort: options.sort,
      filters: {
        query: options.filters.query,
        startDate: options.filters.startDate,
        endDate: options.filters.endDate,
        type: options.filters.type,
        readStatus: options.filters.readStatus,
        isArchived: options.filters?.isArchived,
        hasSummary: options?.filters?.hasSummary,
      },
    },
  ],
  activitiesBadge: (options: ActivitiesBadgeOption) => [
    entities.ACTIVITY_BADGE,
    {
      companyId: options.companyId,
    },
  ],
  exportActivity: (options: ActivitiesBadgeOption) => [
    entities.ACTIVITY_BADGE,
    {
      companyId: options.companyId,
    },
  ],
};

// (string | { companyId: string | null; type: string; })[]
