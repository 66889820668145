export type QueryKeysType = [string, string];

type Params = {
  userId: string;
  businessAccountId: string;
};

const getUserQueryKeys = ({
  userId,
  businessAccountId,
}: Params): QueryKeysType => [userId, businessAccountId];

export default getUserQueryKeys;
