export default {
  baseStyle: {
    field: {},
  },
  sizes: {
    sm: {
      field: {},
    },

    md: {
      field: {},
    },

    lg: {
      field: {},
    },

    xl: {
      field: {},
    },

    "2xl": {
      field: {},
    },
  },

  variants: {
    outline: {
      field: {
        backgroundColor: "#e4ecfc",
        _focus: {
          borderColor: "#e2f8e0",
          boxShadow: "none",
        },
      },
    },
    filled: {
      field: {},
    },
    flushed: {
      field: {},
    },
    unstyled: {
      field: {},
    },
  },
  defaultProps: {},
};
