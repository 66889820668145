import { CompanyInfoType } from "src/hooks/queries/dashboard/useCompanyInfoQuery/useCompanyInfoQuery";
import chainGet from "src/services/network/chainGet";
import { StringOrNull } from "src/types";
import { anyPass, equals } from "ramda";

export enum AbbyStageLiveChatValue {
  "Stage_1" = "1",
  "Stage_2" = "2",
  "Stage_3" = "3",
  "Stage_4" = "4",
  "Stage_5" = "5",
}
export enum AbbyStageValue {
  "Stage_1" = "1",
  "Stage_2" = "2",
  "Stage_3" = "3",
  "Stage_4" = "4",
  "Stage_5" = "5",
}

export enum AbbyStatusLiveChatValue {
  "Current" = "Current",
  "Closed" = "Closed",
}

export enum AbbyStatusValue {
  "Current" = "Current",
  "Closed" = "Closed",
}

export const checkIsFreeTrialStage = (
  stage: AbbyStageValue | AbbyStageLiveChatValue | null
): boolean => {
  if (!stage) return false;

  return anyPass<any>([
    equals(AbbyStageValue.Stage_1),
    equals(AbbyStageValue.Stage_2),
    equals(AbbyStageValue.Stage_3),
    equals(AbbyStageValue.Stage_4),
  ])(stage);
};

export const checkIsReopenTrial = (
  subType: "LIVE_RECEPTIONIST" | "LIVE_CHAT",
  companyInfo?: CompanyInfoType
): boolean | null => {
  if (!companyInfo) return null;

  const abbyStage = chainGet(getStageValue(companyInfo?.abbyStage), "");
  const status = chainGet(companyInfo?.status, "");
  //==
  const abbyStageLiveChat = chainGet(
    getStageLiveChatValue(companyInfo?.abbyStageLiveChat),
    ""
  );
  const statusLiveChat = chainGet(companyInfo?.statusLiveChat, "");

  const stageBasis =
    subType === "LIVE_RECEPTIONIST" ? abbyStage : abbyStageLiveChat;
  const statusBasis = subType === "LIVE_RECEPTIONIST" ? status : statusLiveChat;

  return (
    anyPass<any>([
      equals(AbbyStageValue.Stage_1),
      equals(AbbyStageValue.Stage_2),
      equals(AbbyStageValue.Stage_3),
    ])(stageBasis) && statusBasis === AbbyStatusValue.Closed
  );
};

export const getStageNumValue = (stage: StringOrNull | undefined) => {
  if (!stage) return null;
  for (const value of Object.values(AbbyStageValue)) {
    if (stage?.includes(value)) {
      return parseInt(value);
    }
  }
  return null;
};

export const getStageValue = (stage: StringOrNull) => {
  if (!stage) return null;

  for (const value of Object.values(AbbyStageValue)) {
    if (stage?.includes(value)) {
      return value;
    }
  }

  return null;
};

export const getStageLiveChatValue = (stage: StringOrNull) => {
  if (!stage) return null;

  for (const value of Object.values(AbbyStageLiveChatValue)) {
    if (stage?.includes(value)) {
      return value;
    }
  }

  return null;
};

export const checkIsFreeTrialLiveReceptionist = (
  companyInfo?: CompanyInfoType
) => {
  if (!companyInfo) return null;

  const abbyStage = chainGet(getStageValue(companyInfo?.abbyStage), "");
  const status = chainGet(companyInfo?.status, "");

  const isFreeTrialStage = checkIsFreeTrialStage(abbyStage);

  return isFreeTrialStage && status === AbbyStatusValue.Current;
};

export const checkIsLiveReceptionistActive = (
  companyInfo?: CompanyInfoType
) => {
  if (!companyInfo) return null;

  const abbyStage = chainGet(getStageValue(companyInfo?.abbyStage), "");
  const status = chainGet(companyInfo?.status, "");

  return (
    abbyStage === AbbyStageValue.Stage_5 && status === AbbyStatusValue.Current
  );
};

export const checkIsLiveReceptionistExpired = (
  companyInfo?: CompanyInfoType
) => {
  if (!companyInfo) return null;

  const abbyStage = chainGet(getStageValue(companyInfo?.abbyStage), "");
  const status = chainGet(companyInfo?.status, "");

  const isFreeTrialStage = checkIsFreeTrialStage(abbyStage);

  return isFreeTrialStage && status === AbbyStatusValue.Closed;
};

export const checkIsLiveReceptionistClosed = (
  companyInfo?: CompanyInfoType
) => {
  if (!companyInfo) return null;
  const abbyStage = chainGet(getStageValue(companyInfo?.abbyStage), "");
  const status = chainGet(companyInfo?.status, "");

  return (
    abbyStage === AbbyStageValue.Stage_5 && status === AbbyStatusValue.Closed
  );
};

// =====

export const checkIsLiveChatActive = (companyInfo?: CompanyInfoType) => {
  if (!companyInfo) return null;
  const abbyStageLiveChat = chainGet(
    getStageLiveChatValue(companyInfo?.abbyStageLiveChat),
    ""
  );
  const statusLiveChat = chainGet(companyInfo?.statusLiveChat, "");

  return (
    abbyStageLiveChat === AbbyStageLiveChatValue.Stage_5 &&
    statusLiveChat === AbbyStatusLiveChatValue.Current
  );
};

export const checkIsFreeTrialLiveChat = (companyInfo?: CompanyInfoType) => {
  if (!companyInfo) return null;
  const abbyStageLiveChat = chainGet(
    getStageLiveChatValue(companyInfo?.abbyStageLiveChat),
    ""
  );
  const statusLiveChat = chainGet(companyInfo?.statusLiveChat, "");

  const isFreeTrialStage = checkIsFreeTrialStage(abbyStageLiveChat);

  return isFreeTrialStage && statusLiveChat === AbbyStatusLiveChatValue.Current;
};

export const checkIsLiveChatExpired = (companyInfo?: CompanyInfoType) => {
  if (!companyInfo) return null;
  const abbyStageLiveChat = chainGet(
    getStageLiveChatValue(companyInfo?.abbyStageLiveChat),
    ""
  );
  const statusLiveChat = chainGet(companyInfo?.statusLiveChat, "");

  const isFreeTrialStage = checkIsFreeTrialStage(abbyStageLiveChat);

  return isFreeTrialStage && statusLiveChat === AbbyStatusLiveChatValue.Closed;
};

export const checkIsLiveChatClosed = (companyInfo?: CompanyInfoType) => {
  if (!companyInfo) return null;
  const abbyStageLiveChat = chainGet(
    getStageLiveChatValue(companyInfo?.abbyStageLiveChat),
    ""
  );
  const statusLiveChat = chainGet(companyInfo?.statusLiveChat, "");

  return (
    abbyStageLiveChat === AbbyStageLiveChatValue.Stage_5 &&
    statusLiveChat === AbbyStatusLiveChatValue.Closed
  );
};
