// ** These are the default breakpoints used by ChakraUI
// this is also the breakpoints we use for our app
// If we override this we can break responsive behaviour app-wide
// can be override via theme object

// chakra-ui.com/docs/styled-system/features/responsive-styles
// chakra-ui.com/docs/styled-system/features/responsive-styles#customizing-breakpoints

const breakpoints = {
  sm: "30em", // 480px
  c_525: "525px", //525px
  csm: "39em", // 624px
  md: "48em", // 768px
  lg: "62em", // 992px
  xl: "80em", // 1280px
  "2xl": "96em", // 1536px
  "3xl": "1600px",
};

export default breakpoints;
