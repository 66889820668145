import { FC, useMemo } from "react";
import createContextSet from "src/utils/createContextSet";
import { useForm, UseFormSetValue } from "react-hook-form";

// I can add other context fields here as needed
export type ContactsFilterType = {
  hasActiveFilter: boolean;
  queryFilter: string;
  setValue: UseFormSetValue<{
    queryFilter: string;
  }>;
  clearFilterByType: (filterType: FilterTypes) => void;
};

type ContactsContextReturnType = ContactsFilterType;

const [useContactsFilterCtx, ContactsFilterCtxProvider] =
  createContextSet<ContactsContextReturnType>();

const initialValues = {
  queryFilter: "",
};

export enum FilterTypes {
  QUERY = "query",
}

// I use this context is mainly to avoid prop drilling
const ContactFilterContextProvider: FC = ({ children }) => {
  const hookFormVals = useForm({
    defaultValues: initialValues,
  });

  const { watch, setValue } = hookFormVals;
  const queryFilter = watch("queryFilter");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearFilterByType = (filterType: FilterTypes) => {
    if (filterType === FilterTypes.QUERY) {
      setValue("queryFilter", "");
    }
  };

  const hasActiveFilter = !![queryFilter].find((item) => Boolean(item));

  const contextValue = useMemo(
    () => ({
      hasActiveFilter,
      queryFilter,
      setValue,
      clearFilterByType,
    }),
    [hasActiveFilter, queryFilter, setValue, clearFilterByType]
  );

  return (
    <ContactsFilterCtxProvider value={contextValue}>
      {children}
    </ContactsFilterCtxProvider>
  );
};

export default ContactFilterContextProvider;
export const useContactsFilterContext = () => useContactsFilterCtx();
