import { useEffect } from "react";
import useCompanyInfo from "src/hooks/queries/dashboard/useCompanyInfoQuery/useCompanyInfoQuery";
import useGetUserQuery from "src/hooks/queries/settings/useGetUserQuery/useGetUserQuery";
import { useLoggedInAs } from "src/store/loggedInAs";
import useUserAccount from "src/store/userAccountState";

const Pendo = () => {
  const { data: companyInfoData } = useCompanyInfo();
  const { userAccount } = useUserAccount();
  const userId = userAccount?.id;
  const { data: userData } = useGetUserQuery(userId as string, !!userId);
  const { isAdminLoggedInAsUser } = useLoggedInAs();

  useEffect(() => {
    const pendoKey = process.env.REACT_APP_PENDO_API_KEY;
    if (pendoKey) {
      /** Disable eslint and ignore ts check since they are 3rd party script */
      /* eslint-disable */
      (function (apiKey) {
        (function (p, e, n, d, o) {
          // @ts-ignore
          var v, w, x, y, z;
          // @ts-ignore
          o = p[d] = p[d] || {};
          // @ts-ignore
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              // @ts-ignore
              o[m] =
                // @ts-ignore
                o[m] ||
                function () {
                  // @ts-ignore
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0))
                  );
                };
            })(v[w]);
          y = e.createElement(n);
          // @ts-ignore
          y.async = !0;
          // @ts-ignore
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          // @ts-ignore
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
      })(pendoKey);
      /* eslint-enable */
      /** Disable eslint and ignore ts check end here */

      if (window.pendo && userData && companyInfoData) {
        const visitorId =
          (isAdminLoggedInAsUser ? "int-" : "") + (userData?.guid || "guest");

        window.pendo.initialize({
          visitor: {
            id: visitorId,
            full_name: userData?.name || "guest",
            email: userData?.email || "guest@guest",
          },
          account: {
            id: companyInfoData?.guid || "guest",
            name: companyInfoData?.name || "guest",
          },
        });
      }
    }
  }, [userData, companyInfoData]);

  return <></>;
};

export default Pendo;
