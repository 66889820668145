import { useMemo, FC } from "react";
import createContextSet from "src/utils/createContextSet";
import { useForm, UseFormSetValue } from "react-hook-form";
// import notNull from "src/utils/notNull";

import useSorting from "src/hooks/useSorting";
import { SortEnum } from "src/hooks/queries/activities/activitiesKeys";
import { IUseSortingOption } from "src/hooks/useSorting";

export const templatesFilterTypes = {
  QUERY: "query",
} as const;

export type FilterTypeArgs =
  typeof templatesFilterTypes[keyof typeof templatesFilterTypes];

export type ManageUsersContextReturnType = {
  setValue: UseFormSetValue<{
    queryFilter: string;
    firstNameLetter: string;
  }>;
  //   --
  queryFilter: string;
  firstNameLetter: string;
  clearFilterByType: (filterType: FilterTypeArgs) => void;
  clearAllFilters: () => void;
  hasActiveFilter: boolean;
  sortState: IUseSortingOption;
};

const [useManageUsersFilterCtx, ManageUsersFilterCtxProvider] =
  createContextSet<ManageUsersContextReturnType>();

const initialValues = {
  queryFilter: "",
  firstNameLetter: "A",
};

const initialSortState = {
  sortBy: "name",
  sortType: SortEnum.DESC,
};

const ManageUsersContextProvider: FC = ({ children }) => {
  const sortState = useSorting(initialSortState);

  const hookFormVals = useForm({
    defaultValues: initialValues,
  });

  const { watch, setValue, reset } = hookFormVals;
  const queryFilter = watch("queryFilter");
  const firstNameLetter = watch("firstNameLetter");

  const clearFilterByType = (filterType: FilterTypeArgs) => {
    if (filterType === templatesFilterTypes.QUERY) {
      setValue("queryFilter", "");
    }
  };

  const clearAllFilters = () => {
    reset(initialValues);
  };

  const hasActiveFilter = !![queryFilter].find((item) => Boolean(item));

  const contextValue = useMemo(
    () => ({
      setValue,
      queryFilter,
      firstNameLetter,
      clearFilterByType,
      hasActiveFilter,
      clearAllFilters,
      sortState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, queryFilter, firstNameLetter, sortState]
  );

  return (
    <ManageUsersFilterCtxProvider value={contextValue}>
      {children}
    </ManageUsersFilterCtxProvider>
  );
};

export default ManageUsersContextProvider;
export const useManageUsersFilterContext = useManageUsersFilterCtx;
