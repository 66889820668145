import { ComponentType } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterSpinner from "src/components/shared/CenterSpinner";
import useUserAccount from "src/store/userAccountState";
import { defaultTo } from "ramda";

/* eslint-disable react/display-name */
const withIsLimitedAccessGuard =
  (Component: ComponentType) => (props: Record<string, any>) => {
    const { userAccount } = useUserAccount();
    const permission = defaultTo("limited", userAccount?.permission);
    const isLimitedAccess = permission.toLowerCase() === "limited";
    const navigate = useNavigate();

    useEffect(() => {
      if (isLimitedAccess) {
        return navigate("/dashboard");
      }
    }, [isLimitedAccess, navigate]);

    return isLimitedAccess ? (
      <Flex h="100vh" justify="center" align="center">
        <Text mr={4}> Redirecting Please Wait...</Text>
        <CenterSpinner />
      </Flex>
    ) : (
      <Component {...props} />
    );
  };

export default withIsLimitedAccessGuard;
