import { lazy, Suspense, useEffect } from "react";
import CenterSpinner from "src/components/shared/CenterSpinner";
import { Navigate, Route, Routes } from "react-router-dom";
import withSuspense from "src/hoc/withSuspense";
import withAuthGuard from "src/hoc/withAuthGuard";
//import withDashboardRedirect from "src/hoc/withDashboardRedirect";
import withErrorBoundary from "src/hoc/withErrorBoundary";
import withRoleBasedGuard from "src/hoc/withRoleBasedGuard";
import withActivitiesFilterContext from "src/hoc/withActivitiesFilterContext";
import withContactsFilterContext from "src/hoc/withContactsFilterContext";
import withSubscriptionContext from "src/hoc/withSubscriptionContext";
import withSubscriptionStatusContext from "src/hoc/withSubscriptionStatusContext";
import withStatusTemplateFilterContext from "src/hoc/withStatusTemplateFilterContext";
import withAnouncementFilterContext from "src/hoc/withAnnouncementFilterContext";
import withChangeLogsFilterContext from "src/hoc/withChangeLogsFilterContext";
import withIsBetaGuard from "src/hoc/withIsBetaGuard";
import { compose } from "ramda";
import useUserAccount from "src/store/userAccountState";
import useBetaObserver from "src/hooks/useBetaObserver";
import withManageUsersFilterContext from "src/hoc/withManageUsersFilterContext";
import withSuperAdminRedirect from "src/hoc/withSuperAdminRedirect";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { appVersionSlice, useAppVersion } from "../store/appVersion";
import { useDispatch } from "react-redux";
import store from "src/store";
import fetchAppMeta from "../services/data/fetchAppMeta";
// import CallTagsContextProvider from "src/contexts/CallTagsContext";
import withIsLimitedAccessGuard from "src/hoc/withIsLimitedAccessGuard";
// import Notifications from "./modules/settings/Notifications";

const NewAppVersionModal = () => {
  const { isAppVersionUpdateModalOpen, closeAppVersionUpdateModal } =
    useAppVersion();
  const dispatch = useDispatch();
  const onAppVersionUpdateModalClose = () => {
    dispatch(closeAppVersionUpdateModal());
  };

  return (
    <Modal
      isOpen={isAppVersionUpdateModalOpen}
      onClose={onAppVersionUpdateModalClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="modal-header" fontWeight={700} fontSize="16px">
          NEW APP VERSION DETECTED
        </ModalHeader>
        <ModalBody>
          A new version is available. Update now to experience the new
          improvements.
        </ModalBody>
        <ModalFooter mt={2} justifyContent={"center"}>
          <Button
            variant={"purple"}
            onClick={() => {
              window.location.reload();
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const lazyWithRetries: typeof lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      if (error.name === "ChunkLoadError") {
        const appMeta = await fetchAppMeta();
        if (localStorage.getItem("appVersion") !== appMeta?.version) {
          store.dispatch(
            appVersionSlice.actions.setIsAppVersionUpdateModalOpen(true)
          );
        } else {
          throw error;
        }
      } else {
        // retry 5 times with a delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
        const delay = 2;
        const retries = 5;
        for (let i = 0; i < retries; i++) {
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 * delay ** i)
          );
          const url = new URL(error.sourceURL);
          // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
          url.searchParams.set("t", `${+new Date()}`);
          try {
            return await import(url.href);
          } catch (e) {
            console.error("retry import");
          }
        }
      }
    }
  };
  return lazy(retryImport);
};

const lazyWithSuspense = compose(withSuspense, lazyWithRetries);

const lazyWithSuspenseErrBoundary = compose(
  withErrorBoundary,
  lazyWithSuspense
);

const receptionistFormWrapper = compose(
  withErrorBoundary,
  withIsBetaGuard,
  withIsLimitedAccessGuard,
  withContactsFilterContext,
  lazyWithSuspense
);

const changeLogsWrapper = compose(
  withErrorBoundary,
  withChangeLogsFilterContext,
  withIsLimitedAccessGuard,
  lazyWithSuspense
);

const lazySuspenseRoleGuarded = compose(
  withErrorBoundary,
  withRoleBasedGuard,
  lazyWithSuspense
);
const lazySuspenseGuarded = compose(withAuthGuard, withSuspense, lazy);
const lazySuspenseDashboardRedirect = compose(
  // TODO: Do review, this feature breaks our post-login navigation feature. dont enable until post-login autonavigation tested
  //withDashboardRedirect,
  lazyWithSuspense
);

const activitiesWrapper = compose(lazyWithSuspenseErrBoundary);
const liveCallHandlingWrapper = compose(
  withErrorBoundary,
  withContactsFilterContext,
  lazyWithSuspense
);

const dashboardWrapper = compose(
  withErrorBoundary,
  withSubscriptionStatusContext,
  withSubscriptionContext,
  lazyWithSuspense
);

const announcementWrapper = compose(
  withErrorBoundary,
  withAnouncementFilterContext,
  lazyWithSuspense
);

const mainWrapper = compose(
  withErrorBoundary,
  withAnouncementFilterContext,
  withActivitiesFilterContext,
  lazySuspenseGuarded
);

const subscriptionWrapper = compose(
  withErrorBoundary,
  withSubscriptionStatusContext,
  withSubscriptionContext,
  lazySuspenseRoleGuarded
);

const usageStatisticsWrapper = compose(
  withErrorBoundary,
  withSubscriptionStatusContext,
  withSubscriptionContext,
  lazySuspenseGuarded
);

const statusTemplateWrapper = compose(
  withErrorBoundary,
  withStatusTemplateFilterContext,
  lazySuspenseGuarded
  // lazyWithSuspenseErrBoundary
);

const abbyAiWrapper = compose(
  withErrorBoundary,
  // withIsBetaGuard,
  activitiesWrapper
);
const manageUsersWrapper = compose(
  withErrorBoundary,
  withSuperAdminRedirect,
  withManageUsersFilterContext,
  withContactsFilterContext,
  lazyWithSuspense
);

// const callTagsWrapper = compose(
//   withErrorBoundary,
//   withIsBetaGuard,
//   lazyWithSuspense
// );

const lazyWithSuspenseErrBoundaryBeta = compose(
  withErrorBoundary,
  withIsBetaGuard,
  lazyWithSuspense
);

const companyCallHandlingWrapper = compose(
  withErrorBoundary,
  withChangeLogsFilterContext,
  lazyWithSuspense
);

const Main = mainWrapper(() => import("src/components/pages/Main"));

const Login = lazySuspenseDashboardRedirect(
  () => import("src/components/pages/Login")
);
const SetPassword = lazySuspenseDashboardRedirect(
  () => import("src/components/pages/SetPassword")
);

const ForgotPassword = lazyWithSuspenseErrBoundary(
  () => import("src/components/pages/ForgotPassword")
);

const Companies = lazySuspenseGuarded(
  () => import("src/components/pages/Companies")
);

const Restricted = lazy(() => import("src/components/pages/Restricted"));

const Subscription = subscriptionWrapper(
  () => import("src/components/modules/subscription/Subscription")
);

const Activities = activitiesWrapper(
  () => import("src/components/modules/activities/Activities")
);
const Dashboard = dashboardWrapper(
  () => import("src/components/modules/dashboard/Dashboard")
);
const InCalls = activitiesWrapper(
  () => import("src/components/modules/activities/InCalls")
);
const OutCalls = activitiesWrapper(
  () => import("src/components/modules/activities/OutCalls")
);

const Messages = activitiesWrapper(
  () => import("src/components/modules/activities/Messages")
);
const Chats = activitiesWrapper(
  () => import("src/components/modules/activities/Chats")
);
const AbbyAnswered = activitiesWrapper(
  () => import("src/components/modules/activities/AbbyAnsweredSms")
);
const LiveCallHandling = liveCallHandlingWrapper(
  () => import("src/components/modules/account/LiveCallHandling")
);
const Schedule = lazyWithSuspenseErrBoundaryBeta(
  () => import("src/components/modules/account/ScheduleHandling")
);
const EditContact = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/account/subComponents/EditContact")
);

const CompanyCallHandling = companyCallHandlingWrapper(
  () => import("src/components/modules/account/CompanyCallHandling")
);
const ReceptionistForm = receptionistFormWrapper(
  () => import("src/components/modules/account/ReceptionistForm")
);
const CompanyFaqs = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/account/AddCompanyFaqForm")
);
const CompanyFaqList = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/account/CompanyFaqList")
);
// const AccountInfo = lazyWithSuspenseErrBoundary(
//   () => import("src/components/modules/account/AccountInfo")
// );

const PaymentMethod = lazySuspenseRoleGuarded(
  () => import("src/components/modules/subscription/PaymentMethod")
);

const Invoices = lazySuspenseRoleGuarded(
  () => import("src/components/modules/subscription/invoices/Invoices")
);

const UsageStatistics = usageStatisticsWrapper(
  () =>
    import(
      "src/components/modules/subscription/UsageStatistics/UsageStatistics"
    )
);

const VoicemailSettings = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/settings/VoicemailSettings")
);

const CallSettings = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/settings/CallSettings")
);

const Integrations = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/settings/Integrations")
);

const ChangeLog = changeLogsWrapper(
  () => import("src/components/modules/settings/ChangeLog")
);
//
// const UserManagement = lazyWithSuspenseErrBoundary(
//   () => import("src/components/modules/settings/UserManagement")
// );

const OAuth = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/settings/OAuth")
);

const Archive = activitiesWrapper(
  () => import("src/components/modules/activities/Archive")
);

const MyCurrentStatus = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/account/MyCallStatuses")
);

const CallStatusTemplates = statusTemplateWrapper(
  () => import("src/components/modules/settings/CallStatusTemplates")
);

const BetaProgram = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/settings/BetaProgram")
);

const Announcements = announcementWrapper(
  () => import("src/components/modules/announcements/Announcements")
);

const AbbyArtificialIntelligence = abbyAiWrapper(
  () =>
    import("src/components/modules/abbyIntelligence/AbbyArtificialIntelligence")
);

const AbbyHumanSuggestions = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/abbyIntelligence/AbbyHumanSuggestions")
);

const ManageUsers = manageUsersWrapper(
  () => import("src/components/modules/management/ManageUsers")
);

const SecurityDetails = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/myProfile/SecurityDetails")
);

// const CallTags = callTagsWrapper(
//   () => import("src/components/modules/callTags/CallTags")
// );

const Notifications = lazyWithSuspenseErrBoundary(
  () => import("src/components/modules/settings/Notifications")
);

const Router = () => {
  const { userAccount } = useUserAccount();
  useBetaObserver();

  return (
    <Suspense fallback={<CenterSpinner />}>
      <NewAppVersionModal />
      <Routes>
        <Route path="/" element={<Companies />} />
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/companies" element={<Companies />} />
        <Route path="/setPassword" element={<SetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/login" element={<Login />} />
        <Route path="/403" element={<Restricted />} />
        <Route path="/*" element={<Main />}>
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="announcements/*" element={<Announcements />} />

          {/* ACTIVITIES */}
          <Route path="activities/*" element={<Activities />} />
          <Route path="activities/all-activities" element={<Activities />} />
          <Route path="activities/in-calls" element={<InCalls />} />
          <Route path="activities/out-calls" element={<OutCalls />} />
          <Route path="activities/messages" element={<Messages />} />
          <Route path="activities/chats" element={<Chats />} />
          <Route path="activities/sms" element={<AbbyAnswered />} />
          <Route path="activities/archive" element={<Archive />} />
          {/* ACCOUNT */}
          <Route path="account/*" element={<LiveCallHandling />} />
          <Route path="account/contacts" element={<LiveCallHandling />} />
          {/** Hide schedule page */}
          {/* <Route path="account/schedule" element={<Schedule />} /> */}
          <Route
            path="account/schedule"
            element={<Navigate to="/dashboard" replace />}
          />

          {/* <Route
          path="account/company-call-handling"
          element={<CompanyCallHandling />}
        /> */}
          <Route path="account/company" element={<CompanyCallHandling />} />
          <Route path="account/form" element={<ReceptionistForm />} />
          <Route path="settings/change-logs" element={<ChangeLog />} />
          <Route path="account/company-faqs" element={<CompanyFaqs />} />
          <Route path="account/faqs" element={<CompanyFaqList />} />
          <Route path="account/call-status" element={<MyCurrentStatus />} />

          <Route path="account/edit-contact/:id" element={<EditContact />} />

          {/* <Route path="account/account-info" element={<AccountInfo />} /> */}
          {/* SUBSCRIPTION */}
          <Route path="subscription/*" element={<Subscription />} />
          <Route path="subscription/subscription" element={<Subscription />} />
          <Route
            path="subscription/payment-method"
            element={<PaymentMethod />}
          />
          <Route path="subscription/invoices" element={<Invoices />} />
          <Route
            path="subscription/usage-statistics"
            element={<UsageStatistics />}
          />

          {/* SETTINGS */}
          {["SuperAdmin", "Full"].includes(userAccount?.permission || "") && (
            <Route path="settings/integrations" element={<Integrations />} />
          )}
          {/* <Route path="settings/user-management" element={<UserManagement />} /> */}
          <Route path="settings/call-settings" element={<CallSettings />} />
          <Route
            path="settings/voicemail-settings"
            element={<VoicemailSettings />}
          />
          <Route
            path="settings/status-templates"
            element={<CallStatusTemplates />}
          />
          <Route path="settings/beta-program" element={<BetaProgram />} />

          {/* <Route
            path="settings/call-tags"
            element={
              <CallTagsContextProvider>
                <CallTags />
              </CallTagsContextProvider>
            }
          /> */}
          <Route
            path="settings/call-tags"
            element={<Navigate to="/dashboard" replace />}
          />

          <Route path="settings/notifications" element={<Notifications />} />

          {/* ABBY INTELLIGENCE */}

          <Route
            path="abby-intelligence/abby-artificial-intelligence"
            element={<AbbyArtificialIntelligence />}
          />
          <Route
            path="abby-intelligence/abby-suggestions"
            element={<AbbyHumanSuggestions />}
          />

          {/* MANAGEMENT */}
          <Route path="management/manage-users" element={<ManageUsers />} />

          {/* SECURITY */}
          <Route path="security" element={<SecurityDetails />} />

          {/* OTHERS */}
          <Route path="oauth/callback" element={<OAuth />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
