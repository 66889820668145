import React from "react";
import { useState } from "react";
import { QueryFunction, useQuery, UseQueryOptions } from "react-query";
import usePaginationUtils from "src/hooks/usePaginationUtils";
import { AxiosError } from "axios";

export type Pagination = { currentPage: number; size: number };
export type QueryKeyType<T> = [string, T];
export type QueryKeyPaginationType<T> = [string, T & Pagination];

export type PaginationStateReturnType = {
  handleReset: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  handleNext: () => void;
  handlePrev: () => void;
  pageStart: number;
  pageEnd: number;
  totalItems: number;
  totalPages: number;
};

export interface IPaginationDefaults {
  size?: number;
}

const useDataWithPagination = <T, TData = any>(
  queryKey: QueryKeyType<T>,
  queryFn: QueryFunction<TData, QueryKeyPaginationType<T>>,
  enabled: boolean,
  paginationDefaults?: IPaginationDefaults,
  options?: UseQueryOptions
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(paginationDefaults?.size || 10);

  const paginationQueryKey: QueryKeyPaginationType<T> = [
    queryKey[0],
    { ...queryKey[1], currentPage, size },
  ];

  const query = useQuery({
    queryKey: paginationQueryKey,
    queryFn,
    enabled,
    onError(err: AxiosError) {
      options?.onError && options.onError(err);
    },
  });

  const {
    handleReset,
    handlePrev,
    handleNext,
    pageStart,
    pageEnd,
    totalItems,
    totalPages,
  } = usePaginationUtils({
    currentPage,
    setCurrentPage,
    query,
    size,
    setSize,
  });

  const paginationState = {
    handleReset,
    setCurrentPage,
    currentPage,
    setSize,
    size,
    handleNext,
    handlePrev,
    pageStart,
    pageEnd,
    totalItems,
    totalPages,
  };

  return { ...query, paginationState };
};

export default useDataWithPagination;
