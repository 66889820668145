import { useCallback, useEffect, useState } from "react";
import {
  ActivitySortOptions,
  SortEnum,
} from "./queries/activities/activitiesKeys";

export type IUseSortingOption = [
  sortOption: ActivitySortOptions,
  handleSort: (sortType: string) => void
];

const useSorting = (initState: ActivitySortOptions): IUseSortingOption => {
  const [sortOption, setSortOption] = useState<ActivitySortOptions>(initState);

  const handleSort = (sortBy: string) => {
    setSortOption((prev) => {
      return {
        sortBy,
        sortType:
          prev?.sortType === SortEnum.ASC ? SortEnum.DESC : SortEnum.ASC,
      };
    });
  };

  return [sortOption, handleSort];
};

export default useSorting;
