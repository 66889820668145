import { ComponentType } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterSpinner from "src/components/shared/CenterSpinner";
import { useCompanySelection } from "src/store/companySelectionState";
import { useLoggedInAs } from "../store/loggedInAs";
import usePermissionObserver from "../hooks/usePermissionObserver";

/* eslint-disable react/display-name */
const withAuthGuard =
  (Component: ComponentType) => (props: Record<string, any>) => {
    const { activeCompany } = useCompanySelection();
    const navigate = useNavigate();

    const { isAuthenticated, isAuthenticating } = useLoggedInAs();
    const { isLoading: isLoadingPermissions } = usePermissionObserver();

    // useEffect(() => {
    //   console.log("isAuthenticating", isAuthenticating);
    // }, [isAuthenticating]);

    useEffect(() => {
      if (!isAuthenticated && !isAuthenticating) {
        navigate("/login");
        return;
      }

      if (isAuthenticated && !isAuthenticating && !activeCompany) {
        navigate("/companies");
        return;
      }
    }, [isAuthenticated, navigate, isAuthenticating]);

    return (
      <>
        {isAuthenticating && (
          <Flex h="100vh" justify="center" align="center">
            <Text mr={4}> Authenticating... Please Wait...</Text>
            <CenterSpinner />
          </Flex>
        )}
        {isLoadingPermissions && (
          <Flex h="100vh" justify="center" align="center">
            <Text mr={4}> Checking user permissions... Please Wait...</Text>
            <CenterSpinner />
          </Flex>
        )}
        <Box css={isAuthenticating ? { display: "none" } : {}}>
          <Component {...props} />
        </Box>
      </>
    );
  };

export default withAuthGuard;
