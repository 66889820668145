import { useNavigate, useSearchParams } from "react-router-dom";
import useReloginMutation from "src/hooks/mutations/useReloginMutation";
import { useCompanySelection } from "src/store/companySelectionState";
import { useEffect } from "react";
import useRedirectBeta from "./useRedirectBeta";
import useIsBetaOrigin from "./useIsBetaOrigin";
import useGetUserQuery from "./queries/settings/useGetUserQuery/useGetUserQuery";
import { useLoggedInAs } from "../store/loggedInAs";

const useBetaObserver = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate: relogin } = useReloginMutation();
  const { setActiveCompany } = useCompanySelection();
  const isBetaOrigin = useIsBetaOrigin();
  const { redirectBeta } = useRedirectBeta();
  const { setImpersonatedBy } = useLoggedInAs();
  const { setIsAuthenticating } = useLoggedInAs();
  const { data: userData } = useGetUserQuery();

  const originsEnvOk = [
    (process.env.REACT_APP_BETA_BASE_URL || "").replace(/\/+$/, "") ||
      "https://beta.abby.com",
    (process.env.REACT_APP_PROD_BASE_URL || "").replace(/\/+$/, "") ||
      "https://my.abby.com",
  ].includes(window.location.origin);

  useEffect(() => {
    if (!originsEnvOk) {
      console.warn(`useBetaObserver - disabled: env origins mismatch!`);
    } else {
      console.debug(`useBetaObserver - checking...`);
    }
    checkIfReloginNeeded();
  }, [originsEnvOk]);

  const checkIfRedirectNeeded = async () => {
    if (userData && originsEnvOk) {
      const isBetaUser = userData.isBetaUser;
      const requireRedirectTo =
        isBetaUser && !isBetaOrigin
          ? "beta"
          : !isBetaUser && isBetaOrigin
          ? "prod"
          : null;
      if (requireRedirectTo) {
        console.debug(
          `useBetaObserver - redirecting ${requireRedirectTo.toUpperCase()}`
        );
        redirectBeta(requireRedirectTo === "beta");
      }
    }
  };

  // Force redirect PROD/BETA
  useEffect(() => {
    checkIfRedirectNeeded();
  }, [userData, isBetaOrigin]);

  const checkIfReloginNeeded = async () => {
    if (searchParams.get("token")) {
      const token = searchParams.get("token") || "";
      const activeCompanyIdParam = searchParams.get("activeCompanyId") || "";
      const pathParam = searchParams.get("path") || "";
      const impersonatedByParam = searchParams.get("impersonatedBy") || null;

      console.log("useBetaObserver - starting relogin after redirect");
      relogin(
        { token },
        {
          onSuccess: async () => {
            console.log(
              `useBetaObserver - relogin success, set company "${activeCompanyIdParam}", set impersonated "${impersonatedByParam}" and navigating domestic "${pathParam}"`
            );
            if (activeCompanyIdParam) {
              setActiveCompany(activeCompanyIdParam);
            }
            setImpersonatedBy(impersonatedByParam);
            navigate(pathParam);
          },
          onError: () => {
            console.warn("useBetaObserver - relogin failed");
            navigate("/login");
          },
          onSettled: () => {
            setIsAuthenticating(false);
          },
        }
      );
    } else {
      setIsAuthenticating(false);
    }
  };
};

export default useBetaObserver;
