import { Flex, Textarea, Text } from "@chakra-ui/react";

const TextareaLimited = ({
  max,
  value,
  onChange,
  absoluteCounter,
  ...otherProps
}: any) => {
  const onChangeInternal = (event: any) => {
    event.target.value = event.target.value.substring(0, max);
    onChange(event);
  };

  return (
    <Flex flexDirection="column">
      <Flex>
        <Textarea {...otherProps} value={value} onChange={onChangeInternal} />
      </Flex>
      <Flex justifyContent={"flex-end"} position={"relative"}>
        {absoluteCounter && (
          <Flex position={"absolute"} top={0} right={0}>
            <Text fontSize={12} fontWeight={400} color={"#666666"}>
              {value?.length ?? 0}/{max}
            </Text>
          </Flex>
        )}
        {!absoluteCounter && (
          <Text fontSize={12} fontWeight={400} color={"#666666"}>
            {value?.length ?? 0}/{max}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default TextareaLimited;
