import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Divider,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Flex,
  VStack,
  HStack,
  chakra,
} from "@chakra-ui/react";
import { useEffect } from "react";
import useUserAccount from "src/store/userAccountState";
import useUserAgreement from "src/hooks/mutations/useUserAgreement";
import { MdCheckCircle } from "react-icons/md";
import { useLoggedInAs } from "src/store/loggedInAs";
import useGetUserQuery from "src/hooks/queries/settings/useGetUserQuery/useGetUserQuery";

const AgreementPopup = () => {
  const { userAccount } = useUserAccount();
  const { isAdminLoggedInAsUser, isAuthenticating } = useLoggedInAs();
  const { isLoading: isUserDataLoading } = useGetUserQuery();

  const { mutate: mutateSignAgreement } = useUserAgreement();
  const acceptedTerms = userAccount?.acceptedTerms;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      !isUserDataLoading &&
      userAccount &&
      acceptedTerms === false &&
      !isAdminLoggedInAsUser &&
      !isAuthenticating
    ) {
      onOpen();
    }
  }, [
    userAccount?.id,
    acceptedTerms,
    isAdminLoggedInAsUser,
    isUserDataLoading,
  ]);

  useEffect(() => {
    const topText = document.querySelector("#top-text");
    if (topText) {
      topText.scrollIntoView();
    }
  }, []);

  const handleUserAgreement = () => {
    mutateSignAgreement({ acceptedTerms: true });
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => null}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent borderRadius="10px">
          <ModalHeader color="white" borderRadius="10px" bg="#A6B5E2">
            Abby Connect Terms & Conditions
          </ModalHeader>

          <Divider />
          <ModalBody>
            <Text mb="5" fontSize="sm" id="top-text">
              Last updated: June 7th, 2024
            </Text>
            <Text mb="5" fontSize="sm" id="top-text">
              These Terms and Conditions (“Terms”) are a binding agreement
              between you and TelAssistant LLC dba Abby Connect (“Abby Connect”)
              that govern your access to and use of our website, receptionist
              services (including our related web portal and mobile
              application), live chat services, and any other products or
              services provided by us to you that link to these Terms
              (collectively, the “Services”). By accessing, using, or purchasing
              any Services, you agree to these Terms.
            </Text>
            <Text mb="5" fontSize="sm">
              If you are accessing, using, or purchasing any of the Services on
              behalf of a third party (e.g., a legal entity or organization,
              such as your employer, customer/client, or professional
              association), you represent and warrant that you have the
              authority to bind that third party, and you accept these Terms on
              your own behalf and on behalf of that third party. In that case,
              “you” and “your” refers to both you and that third party.
            </Text>
            <Text mb="5" fontSize="sm">
              These Terms contain a binding arbitration provision and class
              action and jury trial waiver. Please read the arbitration notice
              below carefully, as it governs the disputes between you and Abby
              Connect. By agreeing to these Terms, you waive your right to
              participate in a class-action lawsuit or class-wide arbitration
              and your right to a jury trial.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Eligibility
            </Text>
            <Text mb="5" fontSize="sm">
              In order to use and access the Services, you must be at least 18
              years old, and you must have the power to enter into a binding
              contract with us and not be barred from doing so under any
              applicable laws. You also represent that any information you
              submit to us or the Services is true, accurate, complete, and
              lawful. You may not use or access the Services if we have
              previously suspended or removed you from the Services, or if your
              registration or your use of the Services is not in compliance with
              any and all applicable laws or these Terms. Also, you may not use
              the Services if your use would cause us to violate any applicable
              laws or regulations.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Account Credentials
            </Text>
            <Text mb="5" fontSize="sm">
              To access some of the Services, you must create an account and
              password (“Account Credentials”). You cannot share, transfer, or
              assign your Account Credentials, and any attempted transfer or
              assignment is void. You are solely responsible for maintaining the
              accuracy, truthfulness, completeness, and confidentiality of, and
              you agree to accept responsibility for all activities that occur
              under, your Account Credentials. If you have reason to believe
              that your account is no longer secure (e.g., in the event of a
              loss, theft, or unauthorized disclosure or use of your account ID
              or password), you agree to immediately notify us.
            </Text>
            <Text mb="5" fontSize="sm">
              You may be liable for any losses we or others incur as a result of
              unauthorized use of your Account Credentials. We may, in our sole
              discretion, for any or no reason, and without penalty or notice,
              deny, suspend, or terminate your account, or discontinue providing
              access to all or any part of the Services. If we terminate your
              account or your right to use the Services, you agree that we will
              not be liable to you or any third party for any such termination.
              You may terminate these Terms at any time going forward by
              discontinuing use of all parts of the Services.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Data Privacy
            </Text>
            <Text mb="5" fontSize="sm">
              Our Privacy Policy explains how we collect, use, and disclose your
              personal information when you access and use the Services. You
              must agree to the Privacy Policy to use the Services.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Modification
            </Text>
            <Text mb="5" fontSize="sm">
              From time to time, and at any time, we may modify these Terms. If
              you continue to access and use the Services after such changes are
              effective, you acknowledge and agree to such modifications.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Receptionist Subscription Services
            </Text>
            <Text mb="5" fontSize="sm">
              This section governs your use and purchase of our A.I.-assisted
              human receptionist subscription services (“Receptionist Services”)
              and live chat subscription services (“Live Chat Services”). These
              Terms refer to the Receptionist Services and the Live Chat
              Services individually as a “Subscription Service,” and together as
              the “Subscription Services.”
            </Text>
            <Text mb="5" fontSize="sm">
              Abby Connect’s Subscription Services are intended for use in the
              United States and Canada. Standard Receptionist Services are
              available during normal business hours: 5am-9pm PST weekdays, and
              6am-6pm PST on weekends. Standard Subscription Services are
              unavailable during the following U.S. holidays: Memorial Day,
              Independence Day, Labor Day, Thanksgiving Day, Black Friday,
              Christmas Day, and New Year’s Day. Office and answering hours will
              be limited to 5 am – 12 pm PST on the following days: Thanksgiving
              Eve, Christmas Eve, and New Year’s Eve.
            </Text>
            <Text mb="5" fontSize="sm">
              If you have paid for the 24/7/365 add-on for Receptionist
              Services, your upgraded Receptionist Services will operate 24
              hours per day, 7 days per week, 365 days of the year (including on
              U.S. holidays).
            </Text>
            <Text mb="5" fontSize="sm">
              Standard Live Chat Services operate 24/7/365 at no additional
              cost.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Subscription Term and Automatic Renewal
            </Text>
            <Text mb="5" fontSize="sm">
              All Subscription Services (including Receptionist Services Pricing
              Plans, Live Chat Pricing Plans, and Enterprise Plans) are
              month-to-month and automatically renew on a monthly basis. If you
              do not want your Subscription Services to renew at the end of the
              current subscription term, you must contact us at least 30 days
              before your subscription is set to renew and notify us of
              cancellation.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Cancellations and Refunds
            </Text>
            <Text mb="5" fontSize="sm">
              During the first 30 days of a paid Subscription Service, you can
              cancel your paid subscription at any time. After the first 30 days
              of your paid Subscription Service, you must provide us with at
              least 30 days’ advance notice of your request to cancel the
              Subscription Service. Your cancellation will be effective at the
              end of the billing cycle during which the 30-day notice period
              expires. We do not prorate subscription fees, and you will remain
              responsible for paying for all Subscription Services that you
              receive prior to the effective date of termination. For the
              avoidance of doubt, this means that if you used any Overage
              Minutes or Overage Chats or incurred any additional fees during
              the last month of your Subscription Services for which you did yet
              pay, we may still bill you for those additional charges after
              termination.
            </Text>
            <Text mb="5" fontSize="sm">
              Because of the nature of the Services, we enforce a strict no
              refund policy. Once you make a payment for the Subscription
              Services, Abby Connect has no obligation to provide you a refund
              for any reason. That said, we want you to be happy with our
              Services, so please don’t hesitate to reach out to us if you are
              dissatisfied with the Services. We will work with you to see if we
              can make it right.
            </Text>
            {/** */}
            <Text mb="2" fontWeight="bold" color={"#1b339f"}>
              Receptionist Services Terms
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Receptionist Services Fees and Pricing Plans
            </Text>
            <Text mb="5" fontSize="sm">
              All Receptionist Services paid subscriptions incur a one-time
              setup fee of $95.00 (“Receptionist Services Setup Fee”) which will
              be charged to your first billing cycle. The Receptionist Services
              Setup Fee only applies to setup of the Receptionist Services and
              cannot be combined or credited against any other setup fees (e.g.,
              for Live Chat).
            </Text>
            <Text mb="5" fontSize="sm">
              We offer paid subscriptions for the Receptionist Services under
              the following pricing plans (“Receptionist Services Pricing
              Plans”):
            </Text>
            <Box mt={"10px"} bg={"#fff"} maxW={{ base: "345px", md: "100%" }}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>Essential Plan</Th>
                      <Th>Professional Plan</Th>
                      <Th>Growth Plan</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "300px",
                          }}
                        >
                          Monthly Subscription Fee
                        </Flex>
                      </Td>
                      <Td>$329</Td>
                      <Td>$599</Td>
                      <Td>$1,380</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "300px",
                          }}
                        >
                          Receptionist Minutes included in Plan
                        </Flex>
                      </Td>
                      <Td>100</Td>
                      <Td>200</Td>
                      <Td>500</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "300px",
                          }}
                        >
                          Overage rate (applies if you exceed the number of
                          Receptionist Minutes included in your Plan)
                        </Flex>
                      </Td>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "100px",
                          }}
                        >
                          $3.29 per Receptionist Minute
                        </Flex>
                      </Td>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "100px",
                          }}
                        >
                          $2.99 per Receptionist Minute
                        </Flex>
                      </Td>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "100px",
                          }}
                        >
                          $2.76 per Receptionist Minute
                        </Flex>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            {/** */}
            <Text mb="1" mt="5" fontWeight="bold" color={"#1b339f"}>
              Receptionist Services Enterprise Plans
            </Text>
            <Text mb="5" fontSize="sm">
              If you are on a standard Receptionist Services Pricing Plan and,
              during any billing period, you exceed 1,000 Receptionist Minutes,
              we may automatically upgrade you to a custom Enterprise Plan
              (“Enterprise Plan”) which will include the number of Receptionist
              Minutes equal to 75% of your monthly estimated Receptionist
              Minutes usage, charged at a rate of $2.76 per Receptionist Minute.
            </Text>
            <Text mb="2" fontSize="sm">
              The monthly estimated Receptionist Minutes will be calculated as
              the sum of:
            </Text>
            <VStack gap="10px">
              <HStack gap="5px">
                <MdCheckCircle size="20" color="#38a169" />
                <Text flex={1} fontSize="sm">
                  Estimated Weekday Usage, which will be calculated by taking
                  the last 3 full weekdays (non-holiday days) of Receptionist
                  Minute usage, dividing by 3 to get a daily average, and
                  multiplying by 20 to get a monthly average; and
                </Text>
              </HStack>
              <HStack gap="5px">
                <MdCheckCircle size="20" color="#38a169" />
                <Text flex={1} fontSize="sm">
                  Estimated Weekend Usage (if any), which will be calculated by
                  adding the number of Receptionist Minutes used on the most
                  recent Saturday and Sunday, and multiplying by 4.
                </Text>
              </HStack>
            </VStack>
            <Text my="5" fontSize="sm">
              For the purpose of calculating the number of Receptionist Minutes
              included in your Enterprise Plan, we multiply the sum of Estimated
              Weekday Usage and Estimated Weekend Usage (if applicable) by .75
              to get 75% of your monthly estimated Receptionist Minutes usage.
            </Text>
            <Text mb="5" fontSize="sm">
              For example, a client with only weekday usage which used 100
              Receptionist Minutes, 150 Receptionist Minutes, and 125
              Receptionist Minutes in the last 3 weekdays would be upgraded to
              an Enterprise Plan which has Estimated Weekday Usage = (100 + 150
              + 125) / 3 x 20 = 2,500 Receptionist Minutes. This client's custom
              Enterprise Plan would include 75% of the 2,500 Receptionist
              Minutes of Estimated Weekday Usage, which is equal to 1,875
              Receptionist Minutes. The Enterprise Plan would be billed at
              $5,175 per month = 1,875 Receptionist Minutes x $2.76. Any
              additional Receptionist Minutes that the client uses in excess of
              1,875 Receptionist Minutes (“Overage Receptionist Minutes”) would
              be billed at $2.76 per minute.
            </Text>
            <Text mb="5" fontSize="sm">
              As a modified example, if the client also had weekend usage,
              consisting of 20 Receptionist Minutes on the most recent Saturday
              and 10 Receptionist Minutes on the most recent Sunday, which is
              (20 +10) = 30, then Estimated Weekend Usage would be equal to 30 x
              4 = 120 Receptionist Minutes. Then, including Estimated Weekend
              Usage, this client’s total estimated monthly usage would be equal
              to Estimated Weekday Usage + Estimated Weekend Usage = 2,500 + 120
              = 2,620. This client’s Enterprise Plan would include 2,620 x .75 =
              1,965 Receptionist Minutes. This client would be billed for an
              Enterprise Plan at the monthly rate equal to $5,423.40 per month =
              1,965 x $2.76. Overage Receptionist Minutes would be billed at
              $2.76 per minute.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Receptionist Minutes
            </Text>
            <Text mb="5" fontSize="sm">
              Our Receptionist Services Pricing Plans are based on billing units
              that we call “Receptionist Minutes.” Receptionist Minutes are
              billed and calculated based on a two-step rounding formula and
              include ring time, talk time, and transfer time but exclude any
              potential hold time. Specifically, our system measures ring time,
              talk time, and transfer time separately and rounds up each of
              those parts of the call to the nearest second. The sum of rounded
              ring time, talk time, and transfer time is then rounded up to the
              nearest 30-second mark.
            </Text>
            <Text mb="5" fontSize="sm">
              Here is an example: <br />
              <br />
              Actual ring time is 9.1 seconds, actual talk time is 14.5 seconds,
              and actual transfer time is 9.6 seconds. We would first round up
              each part of the call to the nearest second: 10 seconds (ring
              time), 15 seconds (talk time), and 10 seconds (transfer time),
              respectively. The total call time would be calculated as 10
              seconds + 15 seconds + 10 seconds = 35 seconds. This total call
              time would then be rounded up to the nearest 30-second mark and
              billed as one (1) Receptionist Minute.
            </Text>
            <Text mb="5" fontSize="sm">
              For inbound calls, Receptionist Minutes are calculated starting
              from the time the call rings into our system, excluding any
              potential hold time, and ending when an Abby Connect receptionist
              transfers the call through to someone, or to voicemail, or
              otherwise disconnects because the call is over.
            </Text>
            <Text mb="5" fontSize="sm">
              For outbound calls, Receptionist Minutes are calculated starting
              from the time the Abby Connect receptionist initiates the outbound
              call, and ending when the Abby Connect receptionist disconnects
              because the call is over. For purposes of calculating Receptionist
              Minutes for outbound calls, all time that an Abby Connect
              receptionist spends connecting or connected on a call will be
              included, including time waiting for the caller to pick up or for
              the call to connect, dialing wrong numbers (if incorrect contact
              information is provided by a client), and time spent leaving a
              voicemail (if applicable).
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Number Porting
            </Text>
            <Text mb="5" fontSize="sm">
              Any telephone number assigned to you by Abby Connect shall remain
              the property of Abby Connect. Any telephone number ported to Abby
              Connect by you shall remain your property. Abby Connect will allow
              you to port away from the telephone numbers, so long as you have
              an account in good standing and, for numbers assigned to you by
              Abby Connect, you have been assigned the number more than thirty
              (30) days prior to the port-away date.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Call Summaries
            </Text>
            <Text mb="5" fontSize="sm">
              If you have opted in to call recording, Abby Connect will notify
              the caller at the beginning of the call that the call is being
              recorded and record the call; both the caller’s voice and the Abby
              Connect receptionist’s voice will be recorded. If you have
              declined call recording, Abby Connect may still record the
              receptionist’s voice but will not record the caller. You
              acknowledge, understand, and agree that Abby Connect uses
              third-party service providers to transcribe the call recordings
              and produce summaries of the transcriptions, which summaries we
              make available to you in our web portal and the Abby Connect App
              (defined below). Abby Connect makes no representation or warranty
              whatsoever, express or implied, as to the accuracy, reliability,
              or adequacy of the call transcriptions or call summaries.
            </Text>
            <Text mb="5" fontSize="sm">
              By opting in to call recording: (a) you agree that you are solely
              responsible for ensuring that any such call recording complies
              with applicable laws; and (b) you represent, warrant, and covenant
              that you have and shall maintain throughout the term of these
              Terms all necessary rights, consents, and authorizations to
              provide the caller’s personal data and/or personal information (as
              those terms are defined by applicable data privacy laws) to Abby
              Connect to use, disclose, retain, and otherwise process as
              contemplated by these Terms and your instructions to Abby Connect.
              You agree to indemnify and hold Abby Connect harmless against any
              costs, expenses, damages, and liabilities, including reasonable
              attorneys’ fees, that Abby Connect may incur as a result of such
              recordings, personal data and/or information, or the use thereof
              as contemplated by these Terms and your instructions to Abby
              Connect.
            </Text>
            <Text mb="2" fontWeight="bold" color={"#1b339f"}>
              Live Chat Services Terms
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Live Chat Services Fees and Pricing Plans
            </Text>
            <Text mb="5" fontSize="sm">
              All Live Chat paid subscriptions incur a one-time setup fee of
              $95.00 (“Live Chat Setup Fee”) which will be charged to your first
              billing cycle. The Live Chat Setup Fee only applies to setup of
              the Live Chat Services and cannot be combined or credited against
              any other setup fees (e.g., for Receptionist Services).
            </Text>
            <Text mb="5" fontSize="sm">
              Live Chat subscription fees vary depending on your plan type and
              whether you have also subscribed to our Receptionist Services. For
              customers who subscribe to Live Chat Services only, the following
              Live Chat Only Pricing Plans apply:
            </Text>
            <Box mt={"10px"} bg={"#fff"} maxW={{ base: "345px", md: "100%" }}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>10 Engaged Chats</Th>
                      <Th>30 Engaged Chats</Th>
                      <Th>50 Engaged Chats</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "300px",
                          }}
                        >
                          Monthly Subscription Fee
                        </Flex>
                      </Td>
                      <Td>$129</Td>
                      <Td>$329</Td>
                      <Td>$469</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "300px",
                          }}
                        >
                          Engaged Chats included in Plan
                        </Flex>
                      </Td>
                      <Td>10 per month</Td>
                      <Td>30 per month</Td>
                      <Td>50 per month</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "300px",
                          }}
                        >
                          Overage Chat Rate (applies if you exceed the number of
                          Engaged Chats included in your Plan)
                        </Flex>
                      </Td>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "100px",
                          }}
                        >
                          $12.90 per Engaged Chat
                        </Flex>
                      </Td>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "100px",
                          }}
                        >
                          $9.96 per Engaged Chat
                        </Flex>
                      </Td>
                      <Td>
                        <Flex
                          align="start"
                          justify="space-between"
                          whiteSpace={"normal"}
                          maxW={{
                            base: "100%",
                            xl: "100px",
                          }}
                        >
                          $9.38 per Engaged Chat
                        </Flex>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

            <Text my="5" noOfLines={[1, 2, 3, 4]} fontSize="sm">
              For customers who concurrently subscribe to both Receptionist
              Services and Live Chat Services, the following Live Chat Add-on
              Pricing Plans apply:
            </Text>

            <TableContainer>
              <Table variant="simple" size="md">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>
                      10 Engaged Chat
                      <br />
                      Add-on
                    </Th>
                    <Th>
                      30 Engaged Chat
                      <br />
                      Add-on
                    </Th>
                    <Th>
                      50 Engaged Chat
                      <br />
                      Add-on
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Flex
                        align="start"
                        justify="space-between"
                        whiteSpace={"normal"}
                        maxW={{
                          base: "100%",
                          xl: "300px",
                        }}
                      >
                        Monthly Subscription Fee
                      </Flex>
                    </Td>
                    <Td>$99</Td>
                    <Td>$249</Td>
                    <Td>$399</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Flex
                        align="start"
                        justify="space-between"
                        whiteSpace={"normal"}
                        maxW={{
                          base: "100%",
                          xl: "300px",
                        }}
                      >
                        Engaged Chats included in Plan
                      </Flex>
                    </Td>
                    <Td>10 per month</Td>
                    <Td>30 per month</Td>
                    <Td>50 per month</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Flex
                        align="start"
                        justify="space-between"
                        whiteSpace={"normal"}
                        maxW={{
                          base: "100%",
                          xl: "300px",
                        }}
                      >
                        Overage Chat Rate (applies if you exceed the number of
                        Engaged Chats included in your Plan)
                      </Flex>
                    </Td>
                    <Td>
                      <Flex
                        align="start"
                        justify="space-between"
                        whiteSpace={"normal"}
                        maxW={{
                          base: "100%",
                          xl: "100px",
                        }}
                      >
                        $9.90 per Additional Chat
                      </Flex>
                    </Td>
                    <Td>
                      <Flex
                        align="start"
                        justify="space-between"
                        whiteSpace={"normal"}
                        maxW={{
                          base: "100%",
                          xl: "100px",
                        }}
                      >
                        $8.30 per Additional Chat
                      </Flex>
                    </Td>
                    <Td>
                      <Flex
                        align="start"
                        justify="space-between"
                        whiteSpace={"normal"}
                        maxW={{
                          base: "100%",
                          xl: "100px",
                        }}
                      >
                        $7.98 per Additional Chat
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            {/** */}
            <Text my="1" mt="5" fontWeight="bold" color={"#1b339f"}>
              Billing and Payments
            </Text>
            <Text mb="5" fontSize="sm">
              When you make a purchase on the Services, you agree to pay the
              applicable fees in effect at the time of your purchase. You agree
              to use a valid and authorized payment method, and you represent
              and warrant that you are authorized to provide and use any payment
              method information that you provide to us. If Abby Connect is
              unable to obtain payment for a purchase that you received from us
              (e.g., if your payment method fails or we receive a chargeback),
              you agree to pay us the corresponding fees within ten (10) days of
              notification from us. We reserve the right to terminate your
              access to any products or services for which we have not received
              adequate payment.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Billing Period
            </Text>
            <Text mb="5" fontSize="sm">
              Your access to paid subscription services will begin on the day
              you subscribe for each relevant Subscription Service. We will
              automatically charge the payment method you provide when you
              register. The day of the month that you first register for any
              Abby Connect Subscription Service will become your “Billing Date.”
              Every month on the Billing Date, we will charge your payment
              method (i.e., your credit, debit card, or checking account) for
              the next month’s Monthly Subscription Fees and any applicable
              one-time fees (e.g., the Setup Fee(s)). At the end of each
              subscription month, we will also calculate and bill you for any
              Overage Receptionist Minutes or Overage Engaged Chats that you
              used during that month.
            </Text>
            <Text mb="5" fontSize="sm">
              For clients who begin separate Subscription Services on different
              dates—e.g., a client begins Receptionist Services on October 15,
              and then begins Live Chat Services on November 10—we prorate the
              first month of the second Subscription Service to align the two
              Service billing dates.
            </Text>
            <Text mb="5" fontSize="sm">
              For example:
            </Text>
            <Text mb="5" fontSize="sm">
              On October 15, a client begins paying for Receptionist Services
              for the Essential Plan ($329 per month). Because the client began
              paying for Receptionist Services on the 15th day of the month, the
              client’s Billing Date will be the 15th of every month thereafter.
              That is, on the 15th of the month, the client will be billed for
              the full Receptionist Services Subscription Fees of $329 for the
              Essential Plan (plus the $95 Receptionist Services Setup Fee, if
              the client is a first-time customer).
            </Text>
            <Text mb="5" fontSize="sm">
              On November 20, the client also registers for our Live Chat Add-on
              Services for the 10-chat Pricing Plan ($99 per month). To align
              the billings date for the client’s two Subscription Services, we
              will prorate the subscription fees for the second Subscription
              Service (Live Chat) based on a 30-day month. In this example, we
              would prorate the Live Chat fees so that the first month’s billing
              period starts on November 20 and ends on December 14 (the day
              before the client’s Billing Date, which is the 15th of the month).
              That means the client’s first billing period for Live Chat
              Services will be 24 days. The first month’s subscription fees for
              the Live Chat Services would be $79.20 = $99 x (24/30). In
              addition, the client would be billed for the $95 Live Chat Setup
              Fee, because the client is a first-time Live Chat customer. The
              number of Engaged Chats available during the first pro-rated month
              is also prorated commensurately and rounded up to the nearest
              whole chat. This means the client will have 8 Engaged Chats
              available during the first month, which is equal to the 10 Engaged
              Chats included under the plan, multiplied by the pro-rated month
              (24/30).
            </Text>
            <Text mb="5" fontSize="sm">
              On December 15, the client will be billed for one full month of
              the Essential Plan Receptionist Services ($329) and one full month
              for 10-chat Live Chat Add-on Services ($99). The client will also
              be billed for any Overages Receptionist Minutes or Overage Chats
              (Engaged Chats in excess of 8 Engaged Chats available to the
              client) that were used during the previous billing period
              (November 15 through December 14 for Receptionist Services, and
              November 20 through December 14 for Live Chat Services).
            </Text>
            <Text mb="5" fontSize="sm">
              On January 15, the client would again be billed for one full month
              of the Essential Plan Receptionist Services ($329) and one full
              month for 10-chat Live Chat Add-on Services ($99). However, this
              time, because the client paid for the full 10 Engaged Chats
              available under their Live Chat Subscription Plan for the previous
              billing period (December 14 through January 14), the client will
              only pay the Overage Chat Rate for any Engaged Chats in excess of
              10 Engaged Chats during the prior billing period.
            </Text>
            <Text mb="5" fontSize="sm">
              This same Billing Date consolidation procedure applies if you
              start Live Chat Services first, and then start Receptionist
              Services second. The Billing Date will be set based to the first
              day you pay for the first Subscription Service (in this second
              example, Live Chat). If you subsequently start paying for
              Receptionist Services, we will prorate your first month’s
              Receptionist Services and Receptionist Services fees to align the
              Receptionist Services Billing Date with your Live Chat Billing
              Date.
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Payment Terms
            </Text>
            <Text mb="5" fontSize="sm">
              All payments must be received by Abby Connect no later than 10
              days after the applicable Billing Date. You must, at all times,
              maintain a valid payment method on file with Abby Connect. You
              hereby authorize Abby Connect to debit your payment method to
              satisfy any and all amounts due to Abby Connect.
            </Text>
            <Text mb="5" fontSize="sm">
              If we do not receive full payment within 30 days of the Billing
              Date, you will be considered to be in material default of this
              Agreement, and Abby Connect will have the right to immediately
              terminate service without waiving the right to collect any and all
              amounts then due plus all Late Charges that may accrue thereafter.
            </Text>
            <Text mb="5" fontSize="sm">
              If you need to change your Billing Date, please contact us.
            </Text>

            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              ACH Debit Authorization
            </Text>
            <Text mb="5" fontSize="sm">
              If you are using an echeck or ACH direct debit as your payment
              method for any Subscription Services, you authorize Abby Connect
              to debit the bank account you provided to us on a monthly
              recurring basis on each Billing Date. You also authorize us to
              debit or credit your account to correct any errors or process any
              refunds, if necessary. You understand that this authorization will
              remain in effect until you notify us of your intent to cancel your
              Subscription Service(s). You further agree to notify us of any
              changes to your account information or termination of this
              authorization prior to the next Billing Date.
            </Text>

            <Text mb="5" fontSize="sm">
              We will debit your account on your Billing Date for the monthly
              subscription fee(s) for each Subscription Service to which you
              have subscribed plus any applicable overage fees from the prior
              billing period. Funds may be withdrawn as soon as the Billing
              Date. If an ACH transaction is rejected for non-sufficient funds,
              you understand that we may attempt to process the charge again
              within 30 days.
            </Text>
            <Text mb="5" fontSize="sm">
              You agree not to dispute Abby Connect debiting your account, so
              long as the transaction corresponds to the terms indicated in your
              subscription order and these Terms and Conditions.
            </Text>

            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Late Charges
            </Text>
            <Text mb="5" fontSize="sm">
              Any payment(s) not received within 10 days of the Due Date, are
              subject to a late charge equal to 5% of the amount then due (“Late
              Charge”).
            </Text>

            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Returned Checks
            </Text>
            <Text mb="5" fontSize="sm">
              A $100 processing fee will be charged for each check (and/or
              electronic check, “echeck”) submitted by you that is returned by
              the financial institution for any reason, including insufficient
              funds and closed accounts. This returned check fee shall be due
              and payable by credit card without demand therefore and must be
              tendered together with the outstanding balance due and all Late
              Charges. If a check (and/or electronic check, “echeck”) is
              returned, Abby Connect reserves the right to require that all
              future payments by you be tendered by credit card.
            </Text>

            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Price Changes
            </Text>
            <Text mb="5" fontSize="sm">
              Abby Connect reserves the right to change the prices and fees for
              the Services at any time. We will notify you of any changes before
              they take effect, and any changes to prices or fees will become
              effective for your next billing cycle. You must cancel your
              Subscription Services before they renew if you disagree with any
              price or fee changes.
            </Text>

            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Abby Connect AbbyGo App
            </Text>
            <Text mb="5" fontSize="sm">
              This section describes the terms and conditions that are specific
              to the Abby Connect mobile application (“Abby Connect App”). The
              Abby Connect App is subject to these Terms and our Privacy Policy.
              By downloading or accessing the Abby Connect App, you acknowledge
              and agree to these Terms and our Privacy Policy.
            </Text>
            <Text mb="5" fontSize="sm">
              The Abby Connect App is intended for users of our Subscription
              Services, and you must have a compatible mobile device and working
              Internet access to use the Abby Connect App. It is your sole
              responsibility to (a) obtain and maintain any equipment and
              network connection necessary for you to use the Abby Connect App,
              and (b) comply with any terms and conditions applicable to your
              equipment and network providers. Abby Connect will have no
              liability to you or any third party for your inability to access
              or use the Services because of a failure of your equipment or
              network connection.
            </Text>
            <Text mb="5" fontSize="sm">
              The Abby Connect App places calls via your Internet or mobile data
              connection. You acknowledge and agree that you may incur charges
              for Internet or mobile data usage and that you are solely
              responsible for paying those charges.
            </Text>
            <Text mb="5" fontSize="sm">
              The Abby Connect App will ask for your permission to access your
              contact list and microphone, and the Abby Connect App may access
              other device features and data as set forth in our Privacy Policy.
              If you do not give your permission for the Abby Connect App to
              access your device data, the Abby Connect App may not work
              properly and some features may be unavailable.
            </Text>
            <Text mb="5" fontSize="sm">
              We may update, change, or withdraw the Abby Connect App at any
              time for any reason, with or without notice to you. We will have
              no liability for your use, or inability to use, the Abby Connect
              App.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Texting
            </Text>
            <Text mb="5" fontSize="sm">
              If you provide us with a mobile phone number and consent to
              receiving mobile phone calls and text messages from us, we may
              contact you about our Services, promotions, and other
              communications that you request from us at the phone number you
              provided. By initiating communication with us via text, you
              consent to us responding to you via text. Standard message and
              data rates will apply to any texts that you send to or receive
              from us. To stop receiving text messages from us, you can reply
              STOP to any text message.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Client Referral Promotion
            </Text>
            <Text mb="5" fontSize="sm">
              If you are an existing or former client, and you refer a new
              client to us who becomes a paid subscriber to our Subscription
              Services, we may credit you with a referral fee equal to $100 (as
              either a gift card or invoice credit) after the new client has
              made their first payment to us. We must be able to verify that you
              are the referral source, and we may deny the referral fee if we
              believe, at our sole discretion, that there has been abuse or
              fraud in connection with this promotional offer or the Services.
              We may discontinue this offer at any time, and we will have no
              obligation to make any referral payments to you after we have
              discontinued this offer.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Your Content
            </Text>
            <Text mb="5" fontSize="sm">
              If you provide data, content, information, or materials (but
              excluding your Feedback) to us (“Your Content”), it is your
              responsibility to ensure that you have all necessary rights and
              licenses to share Your Content with us. You represent and warrant
              that Your Content is accurate, truthful, and lawful; does not
              violate applicable laws or infringe, violate, or misappropriate
              the rights of any third party; and that its use by Abby Connect as
              necessary to deliver the Services to you does not infringe,
              violate, or misappropriate the rights of any third party. You
              grant Abby Connect a worldwide, revocable, royalty-free, right and
              license to use, reproduce, distribute, modify, post, display,
              perform and transmit Your Content to provide the Services to you.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Term and Termination
            </Text>
            <Text mb="5" fontSize="sm">
              You may terminate these Terms by providing written notice to Abby
              Connect and ceasing all use of the Services. However, these Terms
              must remain in effect as long as you are accessing, using, or
              subscribed to any of our Services, and terminating these Terms
              does not in any way diminish your obligation to provide advance
              notice of cancellation applicable to any of our Services. For
              example, if you have a paid Subscription Service, we require 30
              days’ advance notice prior to cancelling your subscription (please
              refer to the{" "}
              <chakra.span fontStyle="italic">
                Cancellations and Refunds
              </chakra.span>{" "}
              section above). Although you can request to terminate these Terms,
              these Terms will nonetheless remain in effect as long as your paid
              Subscription Service is active (subject to the 30 days’ advance
              notice requirement prior to cancellation); your request to
              terminate these Terms will only be effective after cancellation of
              your paid Subscription Service is effective.
            </Text>
            <Text mb="5" fontSize="sm">
              We may suspend, terminate, or deny you access to the Services for
              any reason or no reason, with or without notice to you. Upon
              termination of these Terms, all licenses granted herein will
              automatically terminate.
            </Text>
            <Text mb="5" fontSize="sm">
              It is your sole responsibility to retain backup copies of any of
              Your Content that you upload, share, or provide to the Services.
              If we suspend or terminate your access to the Services, we have no
              obligation to provide you with any further access to Your Content,
              and we may delete or destroy your account information and/or Your
              Content immediately and without notice to you.
            </Text>
            <Text mb="5" fontSize="sm">
              Upon termination of these Terms, any provision which, by its
              nature or express terms should survive, will survive such
              termination or expiration, including without limitation,
              Intellectual Property, Disclaimer, Limitation of Liability,
              Indemnification, Arbitration and Dispute Resolution, and
              Miscellaneous.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Acceptable Use Policy
            </Text>
            <Text mb="2" fontSize="sm">
              You may not engage in any of the following on or using the
              Services:
            </Text>
            <Text mb="5" fontSize="sm">
              1. Reproducing, duplicating, copying, selling, reselling,
              publishing, uploading, posting, transmitting, distributing, or
              otherwise exploiting for any purpose, any portion of, the
              functionality of, or access to the Services;
            </Text>
            <Text mb="5" fontSize="sm">
              2. Selling or renting any part of the Services Materials, any
              derivative works based in whole or in part on the Services
              Materials, or any collective work that includes any part of the
              Services Materials;
            </Text>
            <Text mb="5" fontSize="sm">
              3. Using the Services in violation of any and all applicable laws,
              rules, and regulations, or in connection with pornographic,
              offensive, or obscene subject matter;
            </Text>
            <Text mb="5" fontSize="sm">
              4. Reverse engineering, modifying, altering (including by removing
              or disabling any security or technological safeguards,
              disclaimers, or legends), any portion of, the functionality of, or
              access to the Services;
            </Text>
            <Text mb="5" fontSize="sm">
              5. Deleting or modifying our Trademarks or the copyright or other
              proprietary rights notices on the Services;
            </Text>
            <Text mb="5" fontSize="sm">
              6. Removing, circumventing, disabling, damaging, or otherwise
              interfering with security-related features of the Services,
              features that prevent or restrict use or copying of any Services
              Materials, or features that enforce limitations on the use of the
              Services and Services Materials; or
            </Text>
            <Text mb="5" fontSize="sm">
              7. Intentionally interfering with or damaging the operation of the
              Services or any user’s enjoyment of it, by any means, including
              without limitation by participation in any denial-of-service type
              attacks or by uploading or otherwise disseminating viruses,
              adware, spyware, worms, or other malicious code.
            </Text>
            <Text mb="5" fontSize="sm">
              Any violation of these Terms may result in the immediate
              termination of your account, use, or access to the Services.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Confidentiality
            </Text>
            <Text mb="5" fontSize="sm">
              Abby Connect will use reasonable care to maintain the
              confidentiality of your confidential information provided to us in
              the course of providing the Services. Abby Connect will not
              disclose or use such confidential information, except as
              authorized by you or otherwise compelled by law. Your confidential
              information shall not include any information in the public domain
              through no fault of Abby Connect, or information received from a
              third party having the right to transfer such information.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Intellectual Property
            </Text>
            <Text mb="5" fontSize="sm">
              All content and functionality on the Services, visual interfaces,
              text, graphics, logos, design, information, icons, images,
              services, content, videos, downloadable, or supplementary
              materials, and all other elements of the Services, in addition to
              any concepts, know-how, tools, frameworks, software, applications
              or other technology, algorithms, models, processes, and industry
              perspectives underlying or embedded in the foregoing, along with
              any enhancements to, or selection, arrangement, or derivative
              works thereof (the “Services Materials”) is the property of Abby
              Connect or its licensors and, to the extent applicable, is
              protected by U.S. and international copyright, patent, and
              trademark laws, international conventions, and other applicable
              laws governing intellectual property and proprietary rights.
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Trademark
            </Text>
            <Text mb="5" fontSize="sm">
              The trademarks, service marks, designs, and logos (collectively,
              the “Trademarks”) displayed on the Services are the registered and
              unregistered Trademarks of Abby Connect and its licensors. You
              agree that, except as expressly permitted by us or by our
              licensors, where applicable, you will not use our Trademarks for
              advertising or promotion purposes and that you will not imply any
              endorsement by or relationship with us or our licensors. Further,
              notwithstanding any of the foregoing, you will cease all use of
              our Trademarks immediately upon our request.
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Your Feedback
            </Text>
            <Text mb="5" fontSize="sm">
              If you provide suggestions, feedback, and comments (collectively,
              “Feedback”) to us regarding your experience with the Services, you
              grant Abby Connect the right to use your Feedback for any purpose
              without restriction or payment to you, or any obligation of
              confidentiality. Further, you represent and warrant that you own
              the rights to your Feedback and have the right and authority to
              post, display, distribute, perform, and transmit your Feedback.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Changes to the Services
            </Text>
            <Text mb="5" fontSize="sm">
              We reserve the right to modify, update, suspend, terminate, or
              change the Services and any functions or features of the Services
              at any time, in our sole discretion, including any prices or fees
              for the Services. We will not be liable to you for any losses,
              costs, expenses, or damages in connection with your use of, or
              inability to use, the Services or any features or functions of the
              Services resulting from any such changes.
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Third-Party Websites
            </Text>
            <Text mb="5" fontSize="sm">
              The Services may include links or references to third-party
              websites or services (“Third-Party Sites”) solely as a convenience
              to you. We do not endorse or take any responsibility for
              Third-Party Sites or the information, materials, products, or
              services contained on or accessible through Third-Party Sites.
              Your access to and use of Third-Party Sites, including the
              information, materials, products, or services on or available
              through those Third-Party Sites, is solely at your own risk and
              may be governed by different terms of use and privacy policies.
            </Text>
            <Text mb="1" fontWeight="medium" color={"#1b339f"}>
              Indemnification
            </Text>
            <Text mb="5" fontSize="sm">
              You agree, to the maximum extent permitted by your jurisdiction’s
              laws, to indemnify, defend, and hold harmless Abby Connect and its
              parent, successors, affiliated companies, contractors, officers,
              directors, employees, agents, and its third-party suppliers,
              licensors, and partners (“Abby Connect Parties”) from and against
              all losses, damages, liabilities, demands, judgments, settlements,
              costs and expenses of any kind (including legal fees and
              expenses), from any claim or demand relating to or arising out of
              (i) your access to, use, inability to use, or misuse of the
              Services; (ii) your breach or alleged breach of these Terms, or
              any violation of the Terms, including any breach of the
              representations, warranties, and covenants made herein; or (iii)
              your failure to comply with applicable laws, including without
              limitation the infringement of any intellectual property, privacy,
              or other rights of any person or entity by you or any third-party
              using your Account Credentials. Abby Connect reserves the right,
              at your expense, to assume the exclusive defense and control of
              any matter for which you are required to indemnify us, and you
              agree to cooperate with our defense of these claims. You agree not
              to settle any such matter without our prior written consent. We
              will use reasonable efforts to notify you of any such claim,
              action, or proceeding when we become aware of it.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Disclaimer
            </Text>
            <Text mb="5" fontSize="sm" fontStyle={"italic"} fontWeight="bold">
              The Services and all content and functionality on the Services are
              provided “AS IS,” without warranty of any kind, either express or
              implied, including, without limitation, implied warranties of
              merchantability and fitness for a particular purpose. Abby Connect
              makes no warranties, express or implied, as to the ownership,
              accuracy, or adequacy of the Services, that the Services will meet
              your requirements or expectations or be to your liking, or that
              information offered on or through the Services will be correct,
              timely, reliable, uninterrupted, or free of errors, viruses or
              other harmful components. Abby Connect will have no liability or
              responsibility for any information published on third-party sites
              or any content or services provided by third parties. Abby Connect
              will not be liable for any indirect, incidental, consequential, or
              punitive damages or losses or for lost revenues or profits,
              whether or not advised of the possibility of such damages or
              losses and regardless of the theory of liability. The above
              exclusions apply to the fullest extent permitted by applicable
              laws.
            </Text>
            <Text mb="5" fontSize="sm" fontStyle={"italic"} fontWeight="bold">
              No advice or information, whether oral or written, obtained by you
              from us through our Services will create any warranty,
              representation, or guarantee not expressly stated in this
              agreement.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Limitation of Liability
            </Text>
            <Text mb="5" fontSize="sm" fontStyle={"italic"} fontWeight="bold">
              In no event will Abby Connect or the Abby Connect Parties’ total
              liability to you for all damages (including without limitation
              damages arising from any unsuccessful court action or legal
              dispute, lost business, lost revenues or profits, loss of data,
              damaged equipment or materials, or any other pecuniary or non-
              pecuniary loss or damage of any nature whatsoever), losses, and
              causes of action arising out of or relating to these Terms or the
              Services (whether in contract, tort, warranty, or otherwise),
              exceed the amount, if any, you paid to Abby Connect for accessing
              the Services during the twelve months immediately preceding the
              date of the claim or one hundred dollars ($100), whichever is
              greater. You acknowledge and agree that the disclaimers and the
              limitations of liability set forth in these Terms reflect a
              reasonable and fair allocation of risk between you and the Abby
              Connect Parties, and that these limitations are an essential basis
              to Abby Connect’s ability to make the Services available to you.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Arbitration and Dispute Resolution
            </Text>
            <Text mb="5" fontSize="sm">
              We hope that, if a dispute arises between you and us, we will be
              able to resolve the dispute informally. However, if that is not
              possible, except as provided below, you agree that any cause of
              action, claim, or dispute between you and Abby Connect arising out
              of or related to these Terms or the Services (collectively,
              “Claim(s)”) will be resolved by binding, individual arbitration.
            </Text>
            <Text mb="5" fontSize="sm">
              You may bring a Claim only on your own behalf and cannot seek
              relief through class action or class arbitration. The American
              Arbitration Association will administer all arbitrations under its
              Commercial Arbitration rules. The arbitration will take place in
              Clark County, Nevada, and each party will bear half of the
              arbitration fees and costs.
            </Text>
            <Text mb="5" fontSize="sm">
              Notwithstanding the foregoing, the following Claims may be brought
              in court instead of arbitration: disputes related to intellectual
              property; violations of any of the Prohibited Uses described
              below; and any equitable relief (such as an injunction) necessary
              to protect either party’s rights or property pending the
              completion of arbitration. If for any reason a Claim proceeds in
              court rather than in arbitration, we each waive any right to a
              jury trial.
            </Text>
            <Text mb="5" fontSize="sm">
              You must file a Claim within one (1) year after the Claim arose or
              it will be forever waived and barred.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Miscellaneous
            </Text>
            <Text mb="5" fontSize="sm">
              These Terms and Conditions, our Privacy Policy, our Data
              Processing Agreement (if applicable), and any written agreement
              between the parties that specifically references or incorporates
              these Terms set forth the entire agreement between the parties. In
              the event any content, information, or material on the Services is
              in conflict with these Terms, these Terms will control. The
              failure to exercise or enforce any right or provision of these
              Terms will not constitute a waiver of such right or provision. Any
              waiver of any part of the Terms will be effective only if written
              and signed by the waiving party. These Terms are governed by and
              construed in accordance with the laws of the State of Nevada
              without reference to the principles of conflicts of laws thereof.
              The venue for any legal proceedings will be in Clark County,
              Nevada. If any provision of the Terms is held to be unlawful,
              void, or for any reason is unenforceable, then that provision will
              be limited or eliminated from the Terms to the minimum extent
              necessary and will not affect the validity and enforceability of
              any remaining provisions. You may not assign these Terms without
              Abby Connect’s prior written consent. We may assign these Terms at
              any time without restriction. This Agreement shall be binding upon
              all permitted successors and assigns of the parties hereto. These
              Terms use heading references, which are not a part of the Terms
              and should not be deemed to limit or affect any of the provisions
              in these Terms.
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Contact Us
            </Text>
            <Text mb="10" fontSize="sm">
              If you have questions about these Terms or our Services, please
              <chakra.span
                onClick={() => {
                  window.location.href = "https://www.abby.com/terms/";
                }}
                color="blue.800"
                cursor="pointer"
                _hover={{
                  textDecortion: "underline",
                  fontWeight: 600,
                }}
              >
                {" "}
                contact us.
              </chakra.span>
            </Text>
            {/** */}
            <Text mb="1" fontWeight="bold" color={"#1b339f"}>
              Abby Connect Data Processing Agreement
            </Text>
            <Text mb="5" fontSize="sm">
              Last updated: 6/7/2021
            </Text>
            <Text mb="5" fontSize="sm">
              This Data Processing Agreement (“DPA”) is incorporated into Abby
              Connect’s Terms of Use between you (our Client) and [entity name]
              (“Abby Connect”, “we”, “us” or “our”). Together, the Terms of Use
              and this DPA are referred to as the “Agreement.” This DPA governs
              our collection, use, disclosure, and retention of personal
              information on your behalf when we provide the Subscription
              Services to you. Capitalized terms used but not defined in this
              DPA have the meanings set forth in the Terms of Use. In the event
              of conflict or ambiguity between any of the provisions of this DPA
              and the provisions of the Terms of Use, the provisions of this DPA
              will prevail.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  1.&nbsp;Definitions{"\n"}
                </Text>
              </Box>
              (a) “Applicable Data Protection Laws” means all applicable
              federal, and state, and foreign laws and regulations relating to
              the processing, protection, or privacy of Client Data (as defined
              below), including where applicable, the guidance and codes of
              practice issued by regulatory bodies in any relevant jurisdiction.
              This includes, but is not limited to, the CCPA.(b) “CCPA” means
              the California Consumer Privacy Act of 2018, as amended (Cal. Civ.
              Code §§ 1798.100 to 1798.199), the CCPA Regulations (Cal. Code
              Regs. tit. 11, §§ 999.300 to 999.337), and any related regulations
              or guidance provided by the California Attorney General. Terms
              defined in the CCPA, including “personal information”, “business”,
              “business purposes”, “consumer” and “service provider” shall have
              the same meaning in this Agreement.(c) “Client” means the User who
              purchases or subscribes to our Receptionist services
              (“Subscription Services”).(d) “Client Data” means the personal
              information Abby Connect collects from Client or from Client’s
              customers (“Consumers”) on Client’s behalf in connection with the
              Subscription Services.(e) “Contracted Business Purposes” means the
              Subscription Services described in the Agreement for which Abby
              Connect receives or accesses Client Data.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  2.&nbsp;Roles of the Parties.{" "}
                </Text>
              </Box>
              The parties agree that for the purposes of the CCPA, Client acts
              as a business, and Abby Connect acts as a service provider for
              Client Data. Client does not sell Client Data to Abby Connect
              because Abby Connect will only use Client Data for the Contracted
              Business Purposes and as otherwise permitted by the CCPA.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  3.&nbsp;Scope.{" "}
                </Text>
              </Box>
              The Client Data processed under this DPA relates to the following
              categories of data subjects: Consumers, potential Consumers, and
              Client’s employees or contractors (who are natural persons). The
              Client Data processed under this DPA includes the following
              categories of data: identifiers, customer records information,
              Internet or other electronic network activity, geolocation data,
              professional or employment-related information, commercial
              information, and any other Client Data that may be processed
              pursuant to the Agreement.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  4.&nbsp;Abby Connect’s Obligations.{" "}
                </Text>
              </Box>
              Abby Connect will: (a) only collect, use, retain, or disclose
              Client Data for the Contracted Business Purposes for which Client
              provides or permits access to Client Data; (b) not collect, use,
              retain, disclose, sell, or otherwise make Client Data available
              for Abby Connect’s own commercial purposes or in a way that does
              not comply with the Applicable Data Protection Laws; (c) limit
              Client Data collection, use, retention, and disclosure to
              activities reasonably necessary and proportionate to achieve the
              Contracted Business Purposes or another compatible operational
              purpose; and (d) promptly comply with any Client request or
              instruction requiring Abby Connect to provide, amend, transfer, or
              delete Client Data, or to stop, mitigate, or remedy any
              unauthorized processing. If the CCPA requires Abby Connect to
              disclose Client Data for a purpose unrelated to the Contracted
              Business Purpose, Abby Connect must first inform the Client of the
              legal requirement and give the Client an opportunity to object or
              challenge the requirement, unless the law prohibits such notice.
              If the CCPA permits, Abby Connect may aggregate, de-identify, or
              anonymize personal information so it no longer meets the personal
              information definition, and may use such aggregated,
              de-identified, or anonymized data for its own research and
              development purposes. Abby Connect will not attempt to or actually
              re-identify any previously aggregated, de-identified, or
              anonymized data and will contractually prohibit downstream data
              recipients from attempting to or actually re-identifying such
              data.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  5.&nbsp;Client’s Obligations.{" "}
                </Text>
              </Box>
              Subject to Section 6 below, Client retains control of Client Data
              and remains solely responsible for its compliance with Applicable
              Data Protection Laws, including, without limitation, providing any
              required notices and obtaining any required consents from
              Consumers and its employees, responding to CCPA-related inquiries
              (including verifiable consumer requests), and for the processing
              instructions it gives to Abby Connect. If Client provides specific
              instructions to Abby Connect for processing Client Data, Client
              warrants and represents that Abby Connect’s expected processing of
              Client Data as specifically instructed by Client will comply with
              Applicable Data Protection Laws.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  6.&nbsp;Assistance with Client’s CCPA Obligations.{" "}
                </Text>
              </Box>
              Taking into account the nature of Abby Connect’s processing and
              the information available to Abby Connect, Abby Connect will
              reasonably cooperate and assist Client to the extent necessary for
              Client to comply with its obligations under the CCPA. If Abby
              Connect receives a CCPA-related inquiry directly from a Consumer
              relating to the Consumer’s personal information, Abby Connect will
              direct the Consumer to contact Client directly. For clarity, Abby
              Connect has no obligation to respond substantively to any
              Consumer’s rights request.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  7.&nbsp;Subcontractors.{" "}
                </Text>
              </Box>
              Abby Connect may use subcontractors to provide the Contracted
              Business Services. Any subcontractor used must enter into a
              written agreement with Abby Connect containing data protection
              obligations that provide at least the same level of protection for
              Client Data as those in this DPA, to the extent applicable to the
              nature of the service provided by such subcontractor. At any time,
              Client may request that Abby Connect provide an up-to-date list
              disclosing each subcontractor that it uses to provide the
              Contracted Business Services. Abby Connect remains fully liable to
              the Client for any subcontractor’s performance of its agreement
              obligations.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  8.&nbsp;Confidentiality.{" "}
                </Text>
              </Box>
              Abby Connect will ensure that its employees, authorized agents,
              and any subcontractors are required to maintain the
              confidentiality of the Client Data, including after the end of
              their respective employment, contract, or assignment.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  9.&nbsp;CCPA Warranties and Certification.{" "}
                </Text>
              </Box>
              Both parties will comply with all applicable requirements of the
              CCPA when collecting, using, retaining, or disclosing personal
              information. Abby Connect certifies that it understands this
              Agreement’s and the CCPA’s restrictions and prohibitions on
              selling personal information and retaining, using, or disclosing
              personal information outside of the parties’ direct business
              relationship, and it will comply with them. Abby Connect further
              warrants that it has no reason to believe any CCPA requirements or
              restrictions prevent it from providing any of the Contracted
              Business Purposes or otherwise performing under this Agreement.
              Abby Connect will promptly notify the Client of any changes to the
              CCPA’s requirements that may adversely affect its performance
              under the Agreement.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  10.&nbsp;Data Security.{" "}
                </Text>
              </Box>
              Abby Connect must at all times implement appropriate technical and
              organizational measures designed to safeguard Client Data against
              unauthorized or unlawful processing, access, copying,
              modification, storage, reproduction, display, or distribution, and
              against accidental loss, destruction, or damage. Abby Connect will
              promptly notify the Client if any Client Data is lost or destroyed
              or becomes damaged, corrupted, or unusable. Immediately following
              any unauthorized or unlawful Client Data processing or security
              breach, the parties will coordinate with each other to investigate
              the matter. Abby Connect will reasonably co-operate with the
              Client in notifying affected data subjects and regulatory
              authorities; and making available all relevant records, logs,
              files, data reporting, and other materials required to comply with
              all Applicable Data Protection Laws or as otherwise reasonably
              required by the Client.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  11.&nbsp;Data Return and Destruction.{" "}
                </Text>
              </Box>
              On termination of the Agreement for any reason or expiration of
              its term, Abby Connect will securely destroy or, if directed in
              writing by the Client, return and not retain, all or any Client
              Data related to this agreement in its possession or control,
              except for one copy that it may retain and use for audit purposes
              only. If any law, regulation, or government or regulatory body
              requires Abby Connect to retain any documents or materials that
              Abby Connect would otherwise be required to return or destroy, it
              will notify the Client in writing of that retention requirement,
              giving details of the documents or materials that it must retain,
              the legal basis for retention, and establishing a specific
              timeline for destruction once the retention requirement ends. Abby
              Connect may only use this retained Client Data for the required
              retention reason or audit purposes.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  12.&nbsp;Data Transfers.{" "}
                </Text>
              </Box>
              Client agrees that we may access and process Client Data on a
              global basis as necessary to provide the Subscription Services to
              Client in accordance with the Agreement, and in particular that
              Client Data will be transferred to and processed by Abby Connect
              in the United States and to other jurisdictions where Abby Connect
              and its subcontractors operate.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  13.&nbsp;Audit.{" "}
                </Text>
              </Box>
              Abby Connect will permit Client, or a designated assessor, to
              audit Abby Connect’s compliance with its DPA obligations, at
              Client’s expense, upon at least 10 business days prior notice, and
              no more than once per year during the term. Abby Connect will give
              the Client reasonable assistance to conduct such audits.
            </Text>
            <Text mb="5" fontSize="sm">
              14.&nbsp;This DPA will remain in effect until the termination of
              the Agreement or for as long as Abby Connect carries out Client
              Data processing activities on behalf of Client, whichever occurs
              sooner.
            </Text>
            <Text mb="5" fontSize="sm">
              <Box display={"flex"}>
                <Text fontSize="sm" fontWeight="bold" color={"#1b339f"}>
                  15.&nbsp;Limitation of Liability.{" "}
                </Text>
              </Box>
              Each party’s liability arising out of or related to this DPA,
              whether in contract, tort, or under any other theory of liability,
              is subject to the “Limitation of Liability” section of the Terms
              of Use, and any reference in such section to the liability of a
              party means the aggregate liability of that party under the
              Agreement (the Terms of Use and this DPA together).
            </Text>
          </ModalBody>
          <Divider />

          <ModalFooter justifyContent="flex-start">
            <Button
              px={8}
              _hover={{
                bg: "#E3EBFC",
                color: "#000",
              }}
              fontSize="12px"
              borderRadius="35px"
              bg="linear-gradient(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)"
              textColor="#fff"
              mr={2}
              onClick={handleUserAgreement}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgreementPopup;
