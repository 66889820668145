import { useMutation } from "react-query";
import { agreementForUser } from "src/services/data/agreementForUser";
import useUserAccount from "src/store/userAccountState";
const useUserAgreement = () => {
  const { userAccount, setAcceptedTerms } = useUserAccount();

  const mutation = useMutation(
    (userAgreement: object) => {
      return agreementForUser(
        userAgreement,
        userAccount?.businessAccountId,
        userAccount?.id
      );
    },
    {
      onSuccess: (response) => {
        // BE returns user data for success response
        if (
          null !== response?.acceptedTerms &&
          undefined !== response?.acceptedTerms
        ) {
          setAcceptedTerms(response.acceptedTerms);
        }
      },
    }
  );
  return mutation;
};

export default useUserAgreement;
