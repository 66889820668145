import { FC } from "react";
import createContextSet from "src/utils/createContextSet";
import useCompanyInfo, {
  CompanyInfoType,
} from "src/hooks/queries/dashboard/useCompanyInfoQuery/useCompanyInfoQuery";

import {
  checkIsFreeTrialStage,
  checkIsFreeTrialLiveReceptionist,
  checkIsLiveReceptionistClosed,
  checkIsLiveReceptionistActive,
  checkIsLiveChatActive,
  checkIsFreeTrialLiveChat,
  checkIsLiveReceptionistExpired,
  checkIsLiveChatExpired,
  checkIsLiveChatClosed,
  getStageNumValue,
  getStageValue,
  checkIsReopenTrial,
} from "src/utils/abbyStageUtils";
import chainGet from "src/services/network/chainGet";
import { AbbyStatusValue } from "src/utils/abbyStageUtils";

type SubscriptionStatusCtxReturnType = {
  isLiveReceptionistTrial: boolean | null;
  isLiveReceptionistClosed: boolean | null;
  isLiveReceptionistExpired: boolean | null;
  isLiveReceptionistActive: boolean | null;

  isLiveChatActive: boolean | null;
  isLiveChatTrial: boolean | null;
  isLiveChatExpired: boolean | null;
  isLiveChatClosed: boolean | null;

  companyInfo: CompanyInfoType | undefined;
  isNoService: boolean;
  abbyStageVal: number | null;
  abbyStage2: string | null | undefined;
  abbyLiveChatStageVal: number | null;
  isAbbyStatusClosed: boolean;
  isAbbyStatusLiveChatClosed: boolean;
  isAbbyTrialStage: boolean;
  isAbbyLiveChatTrialStage: boolean;
  isLoading: boolean;
  refetchCompanyInfo: () => Promise<any>;
  isAbbyRecepStartTrial: boolean;
  isAbbyChatStartTrial: boolean;

  isReopenTrialLiveChat: boolean | null;
  isReopenTrialLiveRecep: boolean | null;
};

const [useSubscriptionStatusCtx, SubscriptionStatusCtxProvider] =
  createContextSet<SubscriptionStatusCtxReturnType>();

const SubscriptionStatusProvider: FC = ({ children }) => {
  const {
    data: companyInfo,
    isLoading,
    refetch: refetchCompanyInfo,
  } = useCompanyInfo();

  // if companyInfo.abbyStage and companyInfo.abbyStageLiveChat are both null,
  //  it means there is no active subscription

  const isNoService =
    companyInfo?.abbyStage === null && companyInfo?.abbyStageLiveChat === null;

  // Package/Service Stages
  // 1 - 4 && Current | Closed - Trial Packages (Trial & Expired)
  // 5 && Current | Closed - Paid Packages (Active & Closed)
  const isLiveReceptionistTrial = checkIsFreeTrialLiveReceptionist(companyInfo);
  const isLiveReceptionistClosed = checkIsLiveReceptionistClosed(companyInfo);
  const isLiveReceptionistExpired = checkIsLiveReceptionistExpired(companyInfo);
  const isLiveReceptionistActive = checkIsLiveReceptionistActive(companyInfo);

  const isLiveChatTrial = checkIsFreeTrialLiveChat(companyInfo);
  const isLiveChatExpired = checkIsLiveChatExpired(companyInfo);
  const isLiveChatClosed = checkIsLiveChatClosed(companyInfo);
  const isLiveChatActive = checkIsLiveChatActive(companyInfo);

  const abbyStageVal = getStageNumValue(companyInfo?.abbyStage);
  const abbyLiveChatStageVal = getStageNumValue(companyInfo?.abbyStageLiveChat);
  const status = companyInfo?.status;
  const statusLiveChat = companyInfo?.statusLiveChat;

  const abbyStage2 = companyInfo?.abbyStage2;

  const isAbbyStatusLiveChatClosed = statusLiveChat === AbbyStatusValue.Closed;
  const isAbbyStatusClosed = status === AbbyStatusValue.Closed;

  const abbyStageLiveChat = chainGet(companyInfo?.abbyStageLiveChat, "");
  const abbyStage = chainGet(companyInfo?.abbyStage, "");

  // Company Info Stages 1-4 Trial Stages , 5 - Paid
  const isAbbyTrialStage = checkIsFreeTrialStage(getStageValue(abbyStage));
  const isAbbyLiveChatTrialStage = checkIsFreeTrialStage(
    getStageValue(abbyStageLiveChat)
  );

  // "Start Trial" is where a customer is going to avail a trial package for the first time.
  const isAbbyRecepStartTrial = !!(
    companyInfo &&
    companyInfo.abbyStage === null &&
    companyInfo.status === null
  );

  const isAbbyChatStartTrial = !!(
    companyInfo &&
    companyInfo.abbyStageLiveChat === null &&
    companyInfo.statusLiveChat === null
  );

  const isReopenTrialLiveRecep = checkIsReopenTrial(
    "LIVE_RECEPTIONIST",
    companyInfo
  ) as boolean;

  const isReopenTrialLiveChat = checkIsReopenTrial(
    "LIVE_CHAT",
    companyInfo
  ) as boolean;

  const contextValue = {
    isReopenTrialLiveRecep,
    isReopenTrialLiveChat,

    isAbbyRecepStartTrial,
    isAbbyChatStartTrial,

    isLiveReceptionistTrial,
    isLiveReceptionistClosed,
    isLiveReceptionistExpired,
    isLiveReceptionistActive,

    isLiveChatActive,
    isLiveChatClosed,

    isLiveChatTrial,
    isLiveChatExpired,
    companyInfo,
    isNoService,
    abbyStageVal,
    abbyLiveChatStageVal,
    abbyStage2,
    //
    isAbbyStatusLiveChatClosed,
    isAbbyStatusClosed,
    isAbbyTrialStage,
    isAbbyLiveChatTrialStage,
    isLoading,
    refetchCompanyInfo,
  };

  return (
    <SubscriptionStatusCtxProvider value={contextValue}>
      {children}
    </SubscriptionStatusCtxProvider>
  );
};

export default SubscriptionStatusProvider;
export const useSubscriptionStatusContext = () => useSubscriptionStatusCtx();
