import useUserAccount from "src/store/userAccountState";
import { anyPass, equals } from "ramda";
import type { Pred } from "ramda";
import chainGet from "src/services/network/chainGet";
import permissions from "src/constants/permissions";

type PermissionKeysType = keyof typeof permissions;
type PerimssionValsType = typeof permissions[PermissionKeysType];

const useIsShowSuperAdmin = () => {
  const { userAccount } = useUserAccount();

  const permission = chainGet(userAccount?.permission, "SuperAdmin");

  const permissionLowerCase =
    permission.toLowerCase() as typeof permissions[PermissionKeysType];

  const isShow = anyPass<Pred<PerimssionValsType[]>>([
    equals<PerimssionValsType>(permissions.SUPERADMIN),
  ])(permissionLowerCase);

  return isShow;
};

export default useIsShowSuperAdmin;
