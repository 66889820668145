import { QueryClient, QueryCache, QueryClientProvider } from "react-query";
import { useLoggedInAs } from "./store/loggedInAs";

function AppQueryClient({ children }: any) {
  const { setToken } = useLoggedInAs();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
    queryCache: new QueryCache({
      onError: (error: any) => {
        const errorMessage = error?.response?.data.message;
        const errorCode = error?.response?.data?.status;
        if (
          (errorMessage === "Token is expired" ||
            errorMessage === "Unauthorized") &&
          errorCode === 401
        ) {
          localStorage.removeItem("persist:root");
          setToken(null);
          queryClient.clear();
          window.location.href = "/login?expired=true";
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

export default AppQueryClient;
