import { ComponentType } from "react";
import ManageUsersContextProvider from "src/contexts/ManageUsersFilterContext";

/* eslint-disable react/display-name */
const withManageUsersFilterContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <ManageUsersContextProvider>
        <Component {...props} />
      </ManageUsersContextProvider>
    );
  };

export default withManageUsersFilterContext;
