import { ComponentType } from "react";
// import ActivitiesFilterContextProvider from "src/contexts/ActivitiesFilterContext";
import StatusTemplateFilterContext from "src/contexts/StatusTemplateFilterContext";

/* eslint-disable react/display-name */
const withStatusTemplateFilterContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <StatusTemplateFilterContext>
        <Component {...props} />
      </StatusTemplateFilterContext>
    );
  };

export default withStatusTemplateFilterContext;
