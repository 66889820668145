import axios from "../network/axios";

export const agreementForUser = async (
  acceptedTerms: any,
  businessAccountId: string | undefined,
  userId: string | undefined
) => {
  const response = await axios.patch(
    `/businessAccounts/${businessAccountId}/agreementForUser/${userId}`,
    acceptedTerms
  );

  return response?.data;
};
