import { Text } from "@chakra-ui/react";

const renderInputErr = (
  errKey: string,
  errors: any,
  styles?: Record<string, any>
) => {
  const message = errors[errKey]?.message;
  return (
    message && (
      <Text style={{ color: "red" }} fontSize="14px" {...styles}>
        {message}
      </Text>
    )
  );
};

export default renderInputErr;
