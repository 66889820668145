import entities from "src/constants/entities";

export type QueryKeysType = [typeof entities.CALL_STATUS, OptionsType];

export type OptionsType = {
  companyId: string;
  contactId: string;
};

const getCurrentCallStatusKeys = (options: OptionsType): QueryKeysType => [
  entities.CALL_STATUS,
  {
    companyId: options.companyId,
    contactId: options.contactId,
  },
];

export default getCurrentCallStatusKeys;
