/* eslint-disable no-undef */
import axios, { AxiosInstance } from "axios";

const baseURL = process.env.REACT_APP_API_URL as string;

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    } else {
      config.headers = { authorization: `Bearer ${token}` };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error({ error });
    const errorMessage = error?.response?.data.message;
    if (
      error.response.status === 401 &&
      (errorMessage === "Token is expired" || errorMessage === "Unauthorized")
    ) {
      localStorage.removeItem("token");
      window.location.href = "/login?expired=true";
      return;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
