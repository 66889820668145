import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

interface IAppVersion {
  isAppVersionUpdateModalOpen: boolean;
}

const initialState: IAppVersion = {
  isAppVersionUpdateModalOpen: false,
};

export const appVersionSlice = createSlice({
  name: "appVersion",
  initialState,
  reducers: {
    setIsAppVersionUpdateModalOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAppVersionUpdateModalOpen = payload;
    },
  },
});

export const appVersionReducer = appVersionSlice.reducer;

const selectIsAppVersionUpdateModalOpen = (state: any): boolean =>
  state.appVersion.isAppVersionUpdateModalOpen;

export const useAppVersion = () => {
  const dispatch = useDispatch();
  const actions = appVersionSlice.actions;

  const isAppVersionUpdateModalOpen = useSelector(
    selectIsAppVersionUpdateModalOpen
  );

  const openAppVersionUpdateModal = () =>
    dispatch(actions.setIsAppVersionUpdateModalOpen(true));
  const closeAppVersionUpdateModal = () =>
    dispatch(actions.setIsAppVersionUpdateModalOpen(false));

  return {
    isAppVersionUpdateModalOpen,
    openAppVersionUpdateModal,
    closeAppVersionUpdateModal,
  };
};
