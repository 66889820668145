import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  FormControl,
} from "@chakra-ui/react";
import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { partialRight } from "ramda";
import renderInputErr from "src/utils/renderInputErr";

export type TransferToFormValTypes = {
  transferTo: string;
};

const schema = yup.object().shape({
  transferTo: yup.string().required().label("Transfer To"),
});

export const initialValues = {
  transferTo: "",
};

type Props = {
  isOpen: boolean;
  onClose: any;
  onSave: any;
};

const TransferToModal = ({ isOpen, onClose, onSave }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<TransferToFormValTypes>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    clearErrors();
    reset();
  }, [isOpen]);

  const renderErr = partialRight(renderInputErr, [errors]);

  const onSubmit = (data: TransferToFormValTypes) => {
    onSave(data.transferTo);
    onClose();
  };

  // console.log("TRANSFER TO MODAL RENDERED");

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius="10px"
          maxW="500px"
          mx={{ base: "4%", csm: 0 }}
        >
          <ModalHeader
            className="modal-header"
            fontWeight={700}
            fontSize="16px"
          >
            EDIT CALL STATUS
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={"10px"}>
            <FormControl mt={3}>
              <Text fontWeight={300} fontSize={"14px"}>
                Transfer to? is required to be populated if you want to change
                status to “Taking Calls”
              </Text>
              <Input
                mt={3}
                placeholder="Transfer To"
                _placeholder={{ color: "#A5B5E3" }}
                {...register("transferTo", {
                  setValueAs: (v) => v.trim(),
                })}
                isInvalid={!!errors?.transferTo}
              />
              {renderErr("transferTo")}
            </FormControl>
          </ModalBody>
          <ModalFooter mt={2} justifyContent={"flex-start"}>
            <Button variant={"purple"} onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
            <Button variant={"text"} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TransferToModal;
