import { ComponentType } from "react";
import SubscriptionStatusProvider from "src/contexts/SubscriptionStatusContext";

/* eslint-disable react/display-name */
const withSubscriptionStatusContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <SubscriptionStatusProvider>
        <Component {...props} />
      </SubscriptionStatusProvider>
    );
  };

export default withSubscriptionStatusContext;
