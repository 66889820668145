import format from "date-fns/format";
import ActivityQueryTypes from "src/constants/activityQueryTypes";
import { CompaniesData } from "src/hooks/queries/settings/useCompaniesData";
import { PackageType } from "src/hooks/queries/subscription/useActiveSubscriptionQuery/useActiveSubscription";

export const formateDate = (dateString: string) =>
  format(new Date(dateString), "MMMM dd yyyy ");

export const formateDateTime = (dateString: string) =>
  format(new Date(dateString), "MMMM dd yyyy H:mm");
export const saveCsvFile = (data: any, filename: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
};

export const getTotalChatAndMinuetesUsedSoFar = (
  companyData: CompaniesData[]
) => {
  return companyData.reduce(
    (acc, curr) => {
      const {
        chatsRemaining,
        chatsUsedSoFar,
        minutesRemaining,
        minutesUsedSoFar,
      } = curr;
      if (chatsRemaining !== null) {
        acc.chatsRemaining += chatsRemaining;
      }
      if (chatsUsedSoFar !== null) {
        acc.chatsUsedSoFar += chatsUsedSoFar;
      }
      if (minutesRemaining !== null) {
        acc.minutesRemaining += minutesRemaining;
      }
      if (minutesUsedSoFar !== null) {
        acc.minutesUsedSoFar += minutesUsedSoFar;
      }
      return acc;
    },
    {
      chatsRemaining: 0,
      chatsUsedSoFar: 0,
      minutesRemaining: 0,
      minutesUsedSoFar: 0,
    }
  );
};
export const getTotalChatAndMinutesAllwance = (packages: PackageType[]) => {
  let totalChatAllowance = 0;
  let totalCallAllowance = 0;
  if (packages.length > 0) {
    packages.forEach((packageData: any) => {
      if (packageData.liveChatsInPackage !== null) {
        totalChatAllowance += packageData.liveChatsInPackage;
      }
      if (packageData.liveReceptionistMinutesInPackage !== null) {
        totalCallAllowance += packageData.liveReceptionistMinutesInPackage;
      }
    });
  }
  return { totalChatAllowance, totalCallAllowance };
};

export type TranscriptType = {
  time: string;
  sender: string;
  message: string;
};

export type TranscriptFieldType = {
  label: string;
  value: string;
};

// get chat and sms transcript from a string
export const getChatAndSmsTranscript = (
  transcript: string
): [Array<TranscriptFieldType>, Array<TranscriptType>] => {
  // check if it's a string
  if (typeof transcript !== "string" || ["", "-"].includes(transcript.trim())) {
    return [[], []];
  }
  // split the string into an array via '\n\n'
  const transcriptArray = transcript.replaceAll("\r", "").split(/\n+/);

  const fieldsSeparatorIndex = transcriptArray.findIndex((line: string) =>
    /^-{5,}$/g.test(line)
  );
  let fieldsArrayPart: Array<string> = [];
  let transcriptArrayPart: Array<string>;
  if (fieldsSeparatorIndex !== -1) {
    fieldsArrayPart = transcriptArray.slice(0, fieldsSeparatorIndex);
    transcriptArrayPart = transcriptArray.slice(fieldsSeparatorIndex + 1);
  } else {
    transcriptArrayPart = [...transcriptArray];
  }

  const fieldsResult: Array<TranscriptFieldType> = [];
  const transcriptResult: Array<TranscriptType> = [];
  // Parse fields
  fieldsArrayPart.forEach((line: string) => {
    const [label, value] = line.split(":").map((part: string) => part.trim());
    if (label && value) {
      fieldsResult.push({
        label,
        value,
      });
    }
  });
  // Parse transcript
  for (let i = 0; i < transcriptArrayPart.length - 1; i += 2) {
    const headerLine = transcriptArrayPart[i];
    const parts = /(.+) \((.+)\)/gi.exec(headerLine);
    if (parts && parts[1] && parts[2]) {
      transcriptResult.push({
        sender: parts[1],
        time: parts[2],
        message: transcriptArrayPart[i + 1],
      });
    }
  }
  return [fieldsResult, transcriptResult];
};

/**
 *
 * @param seconds number
 * @returns a string in the format of 00:00:00
 */
export const getHoursMinutesSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = Math.floor(seconds % 60);
  // format the time to 00:00:00
  const formattedTime = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    secondsLeft.toString().padStart(2, "0"),
  ].join(":");
  return formattedTime;
};

export const getActivityType = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case ActivityQueryTypes.INCOMING:
      return ActivityQueryTypes.INCOMING;
    case ActivityQueryTypes.OUTGOING:
      return ActivityQueryTypes.OUTGOING;
    case ActivityQueryTypes.SMS:
      return ActivityQueryTypes.SMS;
    case ActivityQueryTypes.LIVE_CHAT:
      return ActivityQueryTypes.LIVE_CHAT;
    case ActivityQueryTypes.MESSAGES:
      return ActivityQueryTypes.MESSAGES;
    default:
      return ActivityQueryTypes.ALL;
  }
};
