export default {
  baseStyle: {
    field: {},
  },
  sizes: {
    sm: {
      field: {},
    },

    md: {
      field: {},
    },

    lg: {
      field: {},
    },

    xl: {
      field: {},
    },

    "2xl": {
      field: {},
    },
  },

  variants: {
    outline: {
      field: {
        backgroundColor: "#e4ecfc",
      },
    },
    filled: {
      field: {},
    },
    flushed: {
      field: {},
    },
    unstyled: {
      field: {},
    },
  },
  defaultProps: {},
};
