import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

// SAMPLE
// const foo = {
//   id: "a2U770000004HA5EAM",
//   username: "User Scopic 2",
//   email: "user.scopic2@mailinator.com",
//   companies: [
//     {
//       type: "Sub-Account",
//       role: "manager",
//       name: "Hamilton's Fashion Co",
//       id: "00177000009wM8YAAU",
//       contactId: "00377000006mXbaAAE",
//     },
//     {
//       type: "Customer",
//       role: "employee",
//       name: "Hamilton's Racing Cars - Stage 5",
//       id: "00177000009v7nqAAA",
//       contactId: "00377000006mZSQAA2",
//     },
//   ],
//   businessAccountRole: "Admin",
//   businessAccountId: "a0577000000HTllAAG",
//   lastAcceptedTerms: "2022-12-01T19:00:29.000Z",
//   acceptedTerms: true,
//   outboundCallerPhoneNumber: "17027106868",
//   didSms: "7255278118",
//   permission: "Full",
// };

export type UserType = {
  businessAccountId: string;
  businessAccountRole: string | null;
  acceptedTerms: boolean;
  lastAcceptedTerms: string;
  email: string;
  id: string;
  udid: string | null;
  username: string | null;
  permission: string;
  didSms: string;
  outboundCallerPhoneNumber: string;
  isBetaUser: boolean;
};

export type UserAccountStateType = {
  user: UserType | null;
};

const initialState: UserAccountStateType = {
  user: null,
};

// State slice and reducers
export const userAccountSlice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    setUserAccountAction: (state, { payload }: PayloadAction<UserType>) => {
      state.user = payload;
    },
    setUserAccountPermissionAction: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      if (state.user) {
        state.user.permission = payload;
      } else {
        console.warn(
          `Trying to apply user permission "${payload}" on null user account state`
        );
      }
    },
    setAcceptedTermsAction: (state, { payload }: PayloadAction<boolean>) => {
      state.user!.acceptedTerms = payload;
    },
  },
});

// Selectors
const selectUserAccount = createSelector(
  (state: any) => {
    return state.userAccount;
  },
  (userAccount: UserAccountStateType) => userAccount.user
);

export const userAccountReducer = userAccountSlice.reducer;

export const useUserAccount = () => {
  const dispatch = useDispatch();
  const actions = userAccountSlice.actions;
  const {
    setUserAccountAction,
    setAcceptedTermsAction,
    setUserAccountPermissionAction,
  } = actions;

  const setUserAccount = (userAccountObj: UserType) =>
    dispatch(setUserAccountAction(userAccountObj));

  const setAcceptedTerms = (acceptedTerms: boolean) =>
    dispatch(setAcceptedTermsAction(acceptedTerms));

  const setUserAccountPermission = (permission: string) =>
    dispatch(setUserAccountPermissionAction(permission));

  const userAccount = useSelector(selectUserAccount);

  return {
    setAcceptedTerms,
    actions,
    setUserAccount,
    userAccount,
    setUserAccountPermission,
  };
};

export default useUserAccount;
