import React from "react";
import { FC, useState } from "react";
import createContextSet from "src/utils/createContextSet";
import chainGet from "src/services/network/chainGet";
import useGetSubscriptionsQuery, {
  SubscriptionsDataType,
} from "src/hooks/queries/subscription/useGetSubscriptionsQuery/useGetSubscriptionsQuery";
import useActiveSubscriptionQuery, {
  PackageType,
  ActiveSubscriptionType,
} from "src/hooks/queries/subscription/useActiveSubscriptionQuery/useActiveSubscription";
import useTimeZone from "src/hooks/useTimeZone";
import dayjs from "src/utils/dayjs";
import {
  formateDate,
  getTotalChatAndMinuetesUsedSoFar,
  getTotalChatAndMinutesAllwance,
} from "src/utils/helper";
import useCompaniesData from "src/hooks/queries/settings/useCompaniesData";
import { StringOrNull } from "src/types";
import type { InvoicePreviewPayloadType } from "src/hooks/queries/invoices/useInvoicePreview";
import removeNulls from "src/utils/removeNulls";
import { uniqBy } from "ramda";
import { add, intervalToDuration } from "date-fns";

export const checkIsLiveChatPak = (subscriptionPackage: PackageType) =>
  typeof subscriptionPackage.liveChatsInPackage === "number" &&
  subscriptionPackage.liveChatsInPackage > 0;

export const checkIsLiveRecepPak = (subscriptionPackage: PackageType) =>
  typeof subscriptionPackage.liveReceptionistMinutesInPackage === "number" &&
  subscriptionPackage.liveReceptionistMinutesInPackage > 0;

const uniqByVMBox = uniqBy(
  (invoice: InvoicePreviewPayloadType) => invoice.vmBoxCode
);

const uniqByOppId = uniqBy(
  (invoice: InvoicePreviewPayloadType) => invoice.oppId
);
const uniqByPackageName = uniqBy((pak: PackageType) => pak?.name);

type SubscriptionReturnType = {
  setSubscriptionPackages: React.Dispatch<
    React.SetStateAction<InvoicePreviewPayloadType[]>
  >;
  subscriptionPackages: InvoicePreviewPayloadType[];
  packages: PackageType[];
  invoiceId: string;
  totalChatAndCellUsed: {
    chatsRemaining: number;
    chatsUsedSoFar: number;
    minutesRemaining: number;
    minutesUsedSoFar: number;
  };
  totalRecurringPrice: number;
  totalPrice: number;
  billingStartDate: StringOrNull;
  billingEndDate: StringOrNull;
  getSubscriptionPackage: (code: number) => PackageType;
  currentSubscriptionIds: string[];
  addOnList: PackageType[];
  nextPayment: string;
  totalChatAndMinutesAllowance: {
    totalChatAllowance: number;
    totalCallAllowance: number;
  };
  clearAllState: () => void;
  highPricedAddOn: PackageType;
  lowerPriceAddOn: PackageType;
  activeSubscription: ActiveSubscriptionType | undefined;
  liveChatTrialPackages: PackageType[];
  allChatTrialPackages: PackageType[];
  liveReceptionistTrialPackages: PackageType[];
  allRecepTrialPackages: PackageType[];
  totalTrialChatAllowance: number;
  totalTrialMinutesAllowance: number;
  subsData: SubscriptionsDataType;
  //
  setMiscPackages: React.Dispatch<
    React.SetStateAction<InvoicePreviewPayloadType[]>
  >;
  miscPackages: InvoicePreviewPayloadType[];
  //
  setAddOnPackages: React.Dispatch<
    React.SetStateAction<InvoicePreviewPayloadType[]>
  >;
  addOnPackages: InvoicePreviewPayloadType[];
  handleAddAddons: (
    value: Required<Omit<InvoicePreviewPayloadType, "oppId">>
  ) => void;
  handleRemoveAddOn: (vmBoxCode: string) => void;
  // --
  setLiveChatPackageX: React.Dispatch<
    React.SetStateAction<PackageType | null | undefined>
  >;
  liveChatPackageX: PackageType | null | undefined;
  setLiveRecepPackageX: React.Dispatch<
    React.SetStateAction<PackageType | null | undefined>
  >;
  liveRecepPackageX: PackageType | null | undefined;
  setAllPackagesfromChatTrial: (chatPackage: PackageType) => void;
  setAllPackagesfromRecepTrial: (recepPackage: PackageType) => void;
  liveRecepDateRangeLabel: StringOrNull;
  liveChatDateRangeLabel: StringOrNull;
  getCountdownDays: (
    subType: "LIVE_CHAT" | "LIVE_RECEPTIONIST",
    isActive: boolean
  ) => number;
  refetchActiveSub: () => void;
  findAutoVoicemailPackage: () => PackageType | undefined;
  hasAutoVoicemailPackage: boolean;
  isLoading: boolean;
  liveRecepPackageSelection: PackageType[];
  liveChatPackageSelection: PackageType[];
};

const [useSubscriptionCtx, SubscriptionCtxProvider] =
  createContextSet<SubscriptionReturnType>();

const getDateRangeLabel = (startDateString: string, endDateString: string) => {
  if (!startDateString && !endDateString) return null;

  const startStrArr = startDateString.split("-");
  const procStartDate = `${startStrArr[1]}/${startStrArr[2]}/${startStrArr[0]}`;

  const endStrArr = endDateString.split("-");
  const procEndDate = `${endStrArr[1]}/${endStrArr[2]}/${endStrArr[0]}`;

  return `${procStartDate} - ${procEndDate}`;
};

const SubscriptionContextProvider: FC = ({ children }) => {
  const subscriptionsQuery = useGetSubscriptionsQuery();
  const { data: subscriptionQueryData, isLoading: isSubLoading } =
    subscriptionsQuery;

  const {
    data: activeSubscription,
    refetch,
    isLoading: isActiveSubLoading,
  } = useActiveSubscriptionQuery();
  const { data: companyData, isLoading: isCompaniesDataLoading } =
    useCompaniesData();

  const isLoading =
    isSubLoading || isActiveSubLoading || isCompaniesDataLoading;

  const getTimezoneDate = useTimeZone();

  const nextDueDate = chainGet(activeSubscription?.nextDueDate, null);
  const billingEndDate = nextDueDate
    ? getTimezoneDate(nextDueDate, "MM/DD/YYYY")
    : "";
  const billingStartDate = nextDueDate
    ? getTimezoneDate(
        dayjs(nextDueDate).subtract(1, "month").toISOString(),
        "MM/DD/YYYY"
      )
    : "";

  const liveRecepDateRangeLabel = getDateRangeLabel(
    chainGet(activeSubscription?.billingStartDateLiveReceptionist, ""),
    chainGet(activeSubscription?.billingEndDateLiveReceptionist, "")
  );

  const liveChatDateRangeLabel = getDateRangeLabel(
    chainGet(activeSubscription?.billingStartDateLiveChat, ""),
    chainGet(activeSubscription?.billingEndDateLiveChat, "")
  );

  const subsData = chainGet(subscriptionQueryData?.data, []);
  const addOnList = subsData.filter((sub) =>
    [2497, 2499].includes(parseInt(sub.code))
  );

  const priceSortedAddOnList = addOnList.sort(
    (item1: PackageType, item2: PackageType) => {
      const price1 = item1.price;
      const price2 = item2.price;
      return price2 - price1;
    }
  );
  const highPricedAddOn = priceSortedAddOnList[0];
  const lowerPriceAddOn = priceSortedAddOnList[1];

  // for codes*  src/constants/subscriptionPackages.ts
  const getSubscriptionPackage = React.useCallback(
    (code: number) => {
      return subsData.filter((item) => {
        return parseInt(item.code) === code;
      })[0];
    },
    [subsData]
  );
  const [miscPackages, setMiscPackages] = useState<InvoicePreviewPayloadType[]>(
    []
  );
  const [addOnPackages, setAddOnPackages] = useState<
    InvoicePreviewPayloadType[]
  >([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    Array<InvoicePreviewPayloadType>
  >([]);

  const [liveChatPackageX, setLiveChatPackageX] = useState<
    PackageType | null | undefined
  >();
  const [liveRecepPackageX, setLiveRecepPackageX] = useState<
    PackageType | null | undefined
  >();

  const company = chainGet(companyData?.data, []);

  const nextPayment = activeSubscription?.nextDueDate
    ? formateDate(activeSubscription?.nextDueDate)
    : "";

  const allChatTrialPackages = chainGet(
    activeSubscription?.chatTrial?.packages,
    []
  );

  // Packages from Trial Services
  const liveChatTrialPackages = allChatTrialPackages.filter((item) => {
    const isLiveChatPackage = checkIsLiveChatPak(item);

    return isLiveChatPackage;
  });

  const allRecepTrialPackages = chainGet(
    activeSubscription?.receptionistTrial?.packages,
    []
  );
  const liveReceptionistTrialPackages = allRecepTrialPackages.filter((item) => {
    const isLiveRecepPackage = checkIsLiveRecepPak(item);

    return isLiveRecepPackage;
  });

  const totalTrialChatAllowance = 50;
  const totalTrialMinutesAllowance = 500;

  // Packages from paid services
  const packages = chainGet(activeSubscription?.packages, []);
  const currentSubscriptionIds = packages.map((p) => p.id);
  const totalPrice = chainGet(activeSubscription?.totalPrice, 0);
  const totalRecurringPrice = chainGet(
    activeSubscription?.totalRecurringPrice,
    0
  ) as number;
  const invoiceId = chainGet(activeSubscription?.invoiceId, "");
  const totalChatAndCellUsed = getTotalChatAndMinuetesUsedSoFar(company);
  const totalChatAndMinutesAllowance = getTotalChatAndMinutesAllwance(packages);

  // At least 1VM should be assigned to the highest priced invoice
  const handleAddAddons = (
    value: Required<Omit<InvoicePreviewPayloadType, "oppId">>
  ) => setAddOnPackages((prev) => [...prev, value]);

  const handleRemoveAddOn = (vmBoxCode: string) => {
    const newAddOns = addOnPackages.filter(
      (item) => vmBoxCode !== item?.vmBoxCode
    );
    setAddOnPackages(newAddOns);
  };

  const getCountdownDays = (
    subType: "LIVE_CHAT" | "LIVE_RECEPTIONIST",
    isExpired: boolean
  ) => {
    const liveChatFreeTrialEndDate = chainGet(
      activeSubscription?.liveChatFreeTrialEndDate,
      null
    );

    const billingEndDateLiveChat = chainGet(
      activeSubscription?.billingEndDateLiveChat,
      null
    );

    const abbyFreeTrialEndDate = chainGet(
      activeSubscription?.abbyFreeTrialEndDate,
      null
    );

    const billingEndDateLiveReceptionist = chainGet(
      activeSubscription?.billingEndDateLiveReceptionist,
      null
    );

    // FOR "Active" Trial Subscriptions use billingEndDateLiveChat
    // For "Expired" Trial Subscription use liveChatFreeTrialEndDate
    const getEndDateBasis = () => {
      if (subType === "LIVE_CHAT" && isExpired) return liveChatFreeTrialEndDate;

      if (subType === "LIVE_CHAT" && !isExpired) return billingEndDateLiveChat;

      if (subType === "LIVE_RECEPTIONIST" && isExpired)
        return abbyFreeTrialEndDate;

      if (subType === "LIVE_RECEPTIONIST" && !isExpired)
        return billingEndDateLiveReceptionist;

      return null;
    };

    const endDateBasis = getEndDateBasis();

    if (!endDateBasis) return 0;

    const procEndDate = add(new Date(endDateBasis), {
      days: 1,
    });

    const duration = intervalToDuration({
      start: new Date(),
      end: endDateBasis ? procEndDate : new Date(),
    });

    const epochStartDate = new Date().getTime();
    const epochEndDate = procEndDate.getTime();
    const isPastEndDate = epochStartDate > epochEndDate;
    const countdownDays = isPastEndDate ? 0 : (duration.days as number);
    return countdownDays;
  };

  React.useEffect(() => {
    const collatedPackages = [
      {
        id: liveRecepPackageX?.id,
        oppId: liveRecepPackageX?.oppId,
        isLiveReceptionist: true,
      },
      {
        id: liveChatPackageX?.id,
        oppId: liveChatPackageX?.oppId,
        isLiveReceptionist: false,
      },
      ...addOnPackages,
      ...miscPackages.map((item) => ({
        id: item.id,
        oppId: item?.oppId,
        isLiveReceptionist: item.isLiveReceptionist,
      })),
    ] as InvoicePreviewPayloadType[];

    const selectedPackages = collatedPackages.reduce(
      (acc: InvoicePreviewPayloadType[], curr) => {
        if (!curr?.id) {
          return acc;
        }
        const vmBoxCode = curr?.vmBoxCode;
        const oppId = curr?.oppId;
        const isLiveReceptionist = curr?.isLiveReceptionist;

        const newInvoicePreview = removeNulls({
          id: curr.id,
          vmBoxCode,
          oppId,
          isLiveReceptionist,
        });

        acc = [...acc, newInvoicePreview];
        return acc;
      },
      []
    );
    setSubscriptionPackages([...selectedPackages]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liveChatPackageX?.id,
    liveRecepPackageX?.id,
    miscPackages,
    addOnPackages,
  ]);

  const setAllPackagesfromChatTrial = (chatPackage: PackageType) => {
    if (chatPackage) {
      setLiveChatPackageX(chatPackage);

      const liveChatAddOnPackages = allChatTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) {
            const vmBoxCode: string = curr.vmBoxCode;

            return [
              ...acc,
              {
                vmBoxCode,
                id: curr.id,
                oppId: curr?.oppId,
                isLiveReceptionist: false,
              },
            ];
          }
          return acc;
        },
        []
      );

      const liveChatTrialPackageInvoices = allChatTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) return acc;
          if (checkIsLiveChatPak(curr)) return acc;
          if (curr.id === chatPackage.id) return acc;
          // ==
          if (curr?.vmBoxCode) return acc;

          return [
            ...acc,
            {
              id: curr.id,
              oppId: curr?.oppId,
              isLiveReceptionist: false,
            },
          ];
        },
        []
      );

      setAddOnPackages((prev) =>
        uniqByVMBox([...prev, ...liveChatAddOnPackages])
      );
      setMiscPackages((prev) =>
        // uniqById([...prev, ...liveChatTrialPackageInvoices])
        uniqByOppId([...prev, ...liveChatTrialPackageInvoices])
      );
      return;
    }
  };

  const setAllPackagesfromRecepTrial = (recepPackage: PackageType) => {
    if (recepPackage) {
      setLiveRecepPackageX(recepPackage);

      const liveRecepAddOnPackages = allRecepTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (curr?.vmBoxCode) {
            const vmBoxCode = curr.vmBoxCode;
            const newRecepAddOn = removeNulls({
              vmBoxCode,
              id: curr.id,
              oppId: curr?.oppId,
              isLiveReceptionist: true,
            });
            return [...acc, newRecepAddOn];
          }
          return acc;
        },
        []
      );

      const liveRecepTrialPackageInvoices = allRecepTrialPackages.reduce(
        (acc: InvoicePreviewPayloadType[], curr) => {
          if (checkIsLiveRecepPak(curr)) return acc;
          if (curr.id === recepPackage.id) return acc;
          // ===
          if (curr?.vmBoxCode) return acc;
          return [
            ...acc,
            {
              id: curr.id,
              oppId: curr?.oppId,
              isLiveReceptionist: true,
            },
          ];
        },
        []
      );

      setAddOnPackages((prev) =>
        uniqByVMBox([...prev, ...liveRecepAddOnPackages])
      );

      setMiscPackages((prev) =>
        // uniqById([...prev, ...liveRecepTrialPackageInvoices])
        uniqByOppId([...prev, ...liveRecepTrialPackageInvoices])
      );
      return;
    }
  };

  const findAutoVoicemailPackage = () => {
    return (
      chainGet(activeSubscription?.futurePackages, []).find((item) => {
        return item.name === "Auto Voicemail Transcription";
      }) ||
      packages.find((item) => item.name === "Auto Voicemail Transcription")
    );
  };

  const hasAutoVoicemailPackage = !!findAutoVoicemailPackage();

  const clearAllState = () => {
    setAddOnPackages(() => []);
    setLiveChatPackageX(null);
    setLiveRecepPackageX(null);
    setMiscPackages(() => []);
    setSubscriptionPackages(() => []);
  };

  const lrCustomPackages = chainGet(activeSubscription?.lrCustomPackages, [])!;
  const lcCustomPackages = chainGet(activeSubscription?.lcCustomPackages, [])!;

  const getLiveRecepPagkageSelectionList = () => {
    const allRecepPackages = [
      getSubscriptionPackage(1193),
      getSubscriptionPackage(1191),
      getSubscriptionPackage(1190),
      getSubscriptionPackage(1189),
      ...lrCustomPackages,
    ].sort((item1, item2) => {
      const mins1 = item1.liveReceptionistMinutesInPackage!;
      const mins2 = item2.liveReceptionistMinutesInPackage!;
      return mins1 - mins2;
    });
    return allRecepPackages;
  };

  const liveRecepPackageSelection = uniqByPackageName(
    getLiveRecepPagkageSelectionList()
  );

  const getLiveChatPagkageSelectionList = () => {
    const allChatPackages = [
      getSubscriptionPackage(1500),
      getSubscriptionPackage(1501),
      getSubscriptionPackage(1502),
      ...lcCustomPackages,
    ].sort((item1, item2) => {
      const chatCount1 = item1.liveChatsInPackage!;
      const chatCount2 = item2.liveChatsInPackage!;
      return chatCount1 - chatCount2;
    });
    return allChatPackages;
  };

  const liveChatPackageSelection = uniqByPackageName(
    getLiveChatPagkageSelectionList()
  );

  const contextValue = {
    liveRecepPackageSelection,
    liveChatPackageSelection,

    handleAddAddons,
    handleRemoveAddOn,
    setAddOnPackages,
    addOnPackages,
    setMiscPackages,
    miscPackages,
    clearAllState,
    subscriptionPackages,
    setSubscriptionPackages,
    packages,
    invoiceId,
    totalChatAndCellUsed,
    totalPrice,
    totalRecurringPrice,
    billingStartDate,
    billingEndDate,
    getSubscriptionPackage,
    currentSubscriptionIds,
    addOnList,
    nextPayment,
    totalChatAndMinutesAllowance,
    highPricedAddOn,
    lowerPriceAddOn,
    activeSubscription,
    liveReceptionistTrialPackages,
    allRecepTrialPackages,
    liveChatTrialPackages,
    allChatTrialPackages,
    totalTrialChatAllowance,
    totalTrialMinutesAllowance,
    subsData,
    // --
    setLiveChatPackageX,
    liveChatPackageX,
    liveRecepPackageX,
    setLiveRecepPackageX,
    setAllPackagesfromChatTrial,
    setAllPackagesfromRecepTrial,
    liveRecepDateRangeLabel,
    liveChatDateRangeLabel,
    getCountdownDays,
    refetchActiveSub: refetch,
    findAutoVoicemailPackage,
    hasAutoVoicemailPackage,
    isLoading,
  };

  return (
    <SubscriptionCtxProvider value={contextValue}>
      {children}
    </SubscriptionCtxProvider>
  );
};

export default SubscriptionContextProvider;
export const useSubscriptionContext = () => useSubscriptionCtx();
