import axios from "src/services/network/axios";
import { useMutation, useQueryClient } from "react-query";
//import { useToast } from "@chakra-ui/react";
import { useCompanySelection } from "src/store/companySelectionState";
import useUserAccount, { UserType } from "src/store/userAccountState";
import { omit } from "ramda";
import { AxiosError } from "axios";
import { logoutUser } from "./useLogoutMutation";
import useRedirectBeta from "../useRedirectBeta";
import { resolveCompanyId } from "src/utils/companyResolver";
import { useLoggedInAs } from "src/store/loggedInAs";
import { useNavigate } from "react-router-dom";

interface IRelogin {
  token: string;
}

const reloginUser = async (formData: IRelogin) => {
  const body = {
    ...formData,
  };
  return await axios.post(`/auth/relogin`, body);
};

const useReloginMutation = (
  enableImmediateRedirectCheck = false,
  sameUser = true,
  nextPath: string | null = null,
  isLogoutAs = false
) => {
  //const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setCompanies, setActiveCompany, activeCompany } =
    useCompanySelection();
  const { userAccount, setUserAccount } = useUserAccount();
  const { redirectBeta, getExpectedBetaRedirectDirection } = useRedirectBeta();
  const { setToken, setImpersonatedBy, setIsAuthenticating } = useLoggedInAs();

  const prevUserId = userAccount?.id || "";

  return useMutation(reloginUser, {
    onMutate() {
      setIsAuthenticating(true);
    },
    async onSuccess(response) {
      console.debug("useReloginMutation: relogin params", {
        enableImmediateRedirectCheck,
        sameUser,
        nextPath,
        isLogoutAs,
      });
      if (response === undefined) throw Error;

      const token = response?.data?.token;
      const companies = response?.data?.user?.companies || [];
      const user: UserType = response?.data.user;
      const userAccount = user ? omit(["companies"], user) : null;
      const isBetaUser = !!userAccount?.isBetaUser;

      console.debug("useReloginMutation: mutate resp", response?.data);

      const nextCompanyId = sameUser
        ? activeCompany
        : resolveCompanyId(companies);

      // Check if origin correct, otherwise redirect
      if (enableImmediateRedirectCheck) {
        const expectedBetaRedirectDirection =
          getExpectedBetaRedirectDirection(isBetaUser);

        if (expectedBetaRedirectDirection) {
          console.debug(
            `useReloginMutation - redirect ${expectedBetaRedirectDirection.toUpperCase()} by immediate domain check`
          );
          setTimeout(() => {
            logoutUser(prevUserId);
            localStorage.removeItem("persist:root");
            setToken(null);
            setImpersonatedBy(null);
          }, 0);
          redirectBeta(
            expectedBetaRedirectDirection === "beta",
            false,
            token,
            nextCompanyId,
            nextPath,
            isLogoutAs ? false : null
          );
          return;
        }
      }

      if (userAccount && companies) {
        setUserAccount(userAccount);
        setCompanies(companies);
      }

      logoutUser(prevUserId);
      setToken(token);
      queryClient.clear();

      setActiveCompany(nextCompanyId);

      // toast({
      //   title: "Relogin Success",
      //   description: "Relogin Success.",
      //   status: "success",
      //   duration: 4000,
      //   isClosable: true,
      // });
      if (nextPath) {
        console.debug(`useReloginMutation - navigating domestic ${nextPath}`);
        navigate(nextPath);
      }
      setIsAuthenticating(false);
    },
    onError(err: AxiosError) {
      // const errMessage = err?.response?.data?.message;
      // toast({
      //   title: "Relogin Error",
      //   description: errMessage || "Relogin Failed.",
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });
};

export default useReloginMutation;
