import { ComponentType } from "react";
import ErrorBoundary from "src/components/shared/ErrorBoundary";
/* eslint-disable react/display-name */
const withErrorBoundary =
  (Component: ComponentType) => (props: Record<string, any>) =>
    (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );

export default withErrorBoundary;
