import { QueryFunction, useQuery } from "react-query";
import getUserQueryKeys, { QueryKeysType } from "./getUserQueryKeys";
import axios from "src/services/network/axios";

import useUserAccount from "src/store/userAccountState";
import { CompanySelectionType, StringOrNull } from "src/types";
import { useLoggedInAs } from "../../../../store/loggedInAs";

// const foo = {
//   username: "UserScopic2",
//   permission: "Full",
//   outboundCallerPhoneNumber: "17027106868",
//   name: "UserScopic2",
//   lastAcceptedTerms: "2023-03-30T05:33:52.000Z",
//   isBetaUser: null,
//   id: "a2U8N000000H8IhUAK",
//   email: "user-scopic2@mailinator.com",
//   didSms: "702-766-3754",
//   companies: [
//     {
//       type: null,
//       name: "Hamilton Racing Cars - Stage 5",
//       id: "0018N00000CXthyQAD",
//       contactId: "0038N00000Al70KQAR",
//     },
//     {
//       type: null,
//       name: "Hamilton Racing Cars",
//       id: "0018N00000CXwIFQA1",
//       contactId: "0038N00000Al8RsQAJ",
//     },
//   ],
//   businessAccountRole: null,
//   businessAccountId: "a058N000000JfgaQAC",
//   betaLastAcceptedTerms: null,
//   betaAcceptedTerms: false,
//   acceptedTerms: true,
// };

export type GetUserType = {
  username: string;
  id: string;
  guid: string | null;
  email: string;
  companies: Array<CompanySelectionType>;
  businessAccountRole: StringOrNull;
  businessAccountId: string;
  permission: string;

  outboundCallerPhoneNumber: string;
  name: string;
  lastAcceptedTerms: string;
  isBetaUser: string | null;
  didSms: string;
  betaLastAcceptedTerms: string | null;
  betaAcceptedTerms: boolean;
  acceptedTerms: boolean;
};

const getUserFetcher: QueryFunction<GetUserType, QueryKeysType> = async ({
  queryKey,
}) => {
  const userId = queryKey[0];
  const businessAccountId = queryKey[1];

  const response = await axios.get(
    `/businessAccounts/${businessAccountId}/users/${userId}`
  );

  return response?.data;
};

const useGetUserQuery = (customUserId?: string, isEnabled = true) => {
  const { userAccount } = useUserAccount();
  const id = userAccount?.id as string;
  const businessAccountId = userAccount?.businessAccountId || "";
  const { isAuthenticated } = useLoggedInAs();

  const userId = customUserId || id;

  const queryKey = getUserQueryKeys({
    userId,
    businessAccountId,
  });

  const query = useQuery({
    queryKey,
    queryFn: getUserFetcher,
    enabled: !!userId && !!businessAccountId && isAuthenticated && isEnabled,
  });

  return { ...query, queryKey };
};

export default useGetUserQuery;
