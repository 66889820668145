import { ComponentType } from "react";
import ChangeLogsFilterContext from "src/contexts/ChangeLogsFilterContext";

/* eslint-disable react/display-name */
const withChangeLogsFilterContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <ChangeLogsFilterContext>
        <Component {...props} />
      </ChangeLogsFilterContext>
    );
  };

export default withChangeLogsFilterContext;
