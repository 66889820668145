import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-table";
import ActivityQueryTypes from "src/constants/activityQueryTypes";
import { AllActivityType } from "src/hooks/queries/activities/useActivityDetail";

export interface ActivityStateType {
  selectedRows: AllActivityType[];
  enableExport: boolean;
  loading: boolean;
  activtyType?: ActivityQueryTypes;
  error: string | undefined;
}

const initialState: ActivityStateType = {
  selectedRows: [],
  enableExport: false,
  loading: false,
  activtyType: ActivityQueryTypes.ALL,
  error: undefined,
};

type SetActivityType = {
  activtyType: ActivityQueryTypes;
};

export const activityStateSlice = createSlice({
  name: "activityState",
  initialState,
  reducers: {
    setActivityTypeAction: (
      state,
      { payload }: PayloadAction<SetActivityType>
    ) => {
      state.activtyType = payload.activtyType;
    },
    setSelectedRowsAction: (
      state,
      { payload }: PayloadAction<{ selectedRows: AllActivityType[] }>
    ) => {
      if (payload.selectedRows.length > 0) {
        state.selectedRows = payload.selectedRows;
        state.enableExport = true;
      } else {
        state.enableExport = false;
        if (
          state.selectedRows !== undefined &&
          state.selectedRows?.length !== 0
        ) {
          state.selectedRows = [];
        }
      }
    },
  },
});

const selectActivityType = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.activtyType
);

const setSelectedRow = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.selectedRows
);

const enablExport = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.enableExport
);

export const useActivityState = () => {
  const dispatch = useDispatch();
  const actions = activityStateSlice.actions;
  const { setActivityTypeAction, setSelectedRowsAction } = actions;

  const setActivityType = (activtyType: ActivityQueryTypes) =>
    dispatch(setActivityTypeAction({ activtyType }));

  const currentActivityType = useSelector(selectActivityType);
  const selectedRows = useSelector(setSelectedRow);
  const getEnableExport = useSelector(enablExport);

  const setSelectedRows = useCallback(
    (newSelectedRows: AllActivityType[]) => {
      if (selectedRows?.length === newSelectedRows?.length) return null;

      dispatch(setSelectedRowsAction({ selectedRows: newSelectedRows }));
    },
    [selectedRows, dispatch, setSelectedRowsAction]
  );

  return {
    actions,
    setActivityType,
    currentActivityType,
    selectedRows,
    setSelectedRows,
    getEnableExport,
  };
};
export const activityStateReducer = activityStateSlice.reducer;
