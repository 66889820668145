import { ComponentType } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterSpinner from "src/components/shared/CenterSpinner";
import useIsShowSubscriptionTab from "src/hooks/useIsShowSubscriptionTab";

/* eslint-disable react/display-name */
const withRoleBasedGuard =
  (Component: ComponentType) => (props: Record<string, any>) => {
    const isShowSubscription = useIsShowSubscriptionTab();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isShowSubscription) {
        return navigate("/dashboard");
      }
    }, [isShowSubscription, navigate]);

    return !isShowSubscription ? (
      <Flex h="100vh" justify="center" align="center">
        <Text mr={4}> Redirecting Please Wait...</Text>
        <CenterSpinner />
      </Flex>
    ) : (
      <Component {...props} />
    );
  };

export default withRoleBasedGuard;
