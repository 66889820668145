import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { CompaniesArrayType } from "src/types";

export type SelectedCompanyType = string | null;

type CompanySelectionStateType = {
  companies: CompaniesArrayType;
  selectedCompanyId: SelectedCompanyType;
};

const initialState: CompanySelectionStateType = {
  companies: [],
  selectedCompanyId: null,
};

// State slice and reducers
export const companySelectionSlice = createSlice({
  name: "companySelection",
  initialState,
  reducers: {
    setCompanySelection: (
      state,
      { payload }: PayloadAction<CompaniesArrayType>
    ) => {
      state.companies = payload;
    },
    setSelectedCompany(state, { payload }: PayloadAction<string>) {
      state.selectedCompanyId = payload;
    },
  },
});

export const companySelectionReducer = companySelectionSlice.reducer;

// Selectors
const selectCompanySelection = createSelector(
  (state: any) => {
    return state.companySelection;
  },
  (companySelection: CompanySelectionStateType) => companySelection.companies
);

const selectActiveCompany = createSelector(
  (state: any) => {
    return state.companySelection;
  },
  (companySelection: CompanySelectionStateType) =>
    companySelection.selectedCompanyId
);

// Hook to centralize all related actions creators, action dispatch function,  selectors, etc.

export const useCompanySelection = () => {
  const dispatch = useDispatch();
  const actions = companySelectionSlice.actions;
  const { setCompanySelection, setSelectedCompany } = actions;

  const setCompanies = (companies: CompaniesArrayType) =>
    dispatch(setCompanySelection(companies));

  const setActiveCompany = (companyId: string) =>
    dispatch(setSelectedCompany(companyId));

  const activeCompany: SelectedCompanyType = useSelector(selectActiveCompany);
  const companySelection: CompaniesArrayType = useSelector(
    selectCompanySelection
  );
  const activeCompanyObject = companySelection.find(
    (item) => item.id === activeCompany
  );

  return {
    actions,
    setCompanies,
    setActiveCompany,
    activeCompany,
    activeCompanyObject,
    companySelection,
  };
};

export default {};
