import { useMemo } from "react";
import chainGet from "src/services/network/chainGet";

type Params = {
  currentPage: number;
  query: any;
  size: number;
  setCurrentPage: (value: any) => void;
  setSize: (value: number) => void;
};

type PaginationUtilReturnType = {
  handleReset: () => void;
  handleNext: () => void;
  handlePrev: () => void;
  getPageStart: () => number;
  getPageEnd: () => number;
  totalItems: number;
  totalPages: number;
  pageStart: number;
  pageEnd: number;
};

const usePaginationUtils = ({
  currentPage,
  setCurrentPage,
  size,
  query,
}: Params): PaginationUtilReturnType => {
  const totalPages = chainGet(query?.data?.pagination?.pageTotal, 0);
  const totalItems = chainGet(query?.data?.pagination?.totalItem, 0);
  let validCurrentPage = currentPage;
  if (!query.isLoading && query.isSuccess && !query.isFetching) {
    validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));
  }

  return useMemo(() => {
    const noItems = totalItems === 0;

    if (currentPage !== validCurrentPage) {
      setCurrentPage(validCurrentPage);
    }

    const handleNext = () => {
      if (currentPage + 1 <= totalPages)
        setCurrentPage((prev: number) => prev + 1);
    };

    const handlePrev = () => {
      if (currentPage - 1 >= 1) setCurrentPage((prev: number) => prev - 1);
    };

    const getPageStart = () => {
      if (noItems) return 0;
      if (currentPage === 1) return 1;
      return size * currentPage - size + 1;
    };

    const getPageEnd = () => {
      if (noItems) return 0;
      if (currentPage === totalPages) return totalItems;
      return size * currentPage;
    };

    const handleReset = () => {
      setCurrentPage(1);
    };

    const pageStart = getPageStart();
    const pageEnd = getPageEnd();

    return {
      handleReset,
      handleNext,
      handlePrev,
      getPageStart,
      getPageEnd,
      totalItems,
      totalPages,
      pageStart,
      pageEnd,
    };
  }, [currentPage, size, totalPages, totalItems]);
};

export default usePaginationUtils;
