import { useEffect } from "react";
import useGetUserQuery from "./queries/settings/useGetUserQuery/useGetUserQuery";
import useUserAccount from "../store/userAccountState";
import { useLoggedInAs } from "../store/loggedInAs";

const usePermissionObserver = () => {
  const { data: userData, isFetched } = useGetUserQuery();
  const { setUserAccountPermission, userAccount } = useUserAccount();
  const { isAuthenticated } = useLoggedInAs();

  useEffect(() => {
    if (isAuthenticated && userAccount && userData) {
      console.debug(
        `usePermissionObserver: Resolved perms "${userData.permission}"`
      );
      setUserAccountPermission(userData.permission);
    }
  }, [isAuthenticated, userAccount, userData?.permission]);

  return {
    isLoading: isAuthenticated && !isFetched,
  };
};

export default usePermissionObserver;
