export default {
  baseStyle: {
    track: {
      _focus: {
        boxShadow: "none",
      },
    },
  },
  variants: {
    status: {
      track: {
        background: "#E80B0B",
        _checked: {
          background: "#2ABD1D",
        },
      },
      thumb: {
        background: "#FFFFFF",
      },
    },
  },
};
