import { ComponentType } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterSpinner from "src/components/shared/CenterSpinner";
import useIsShowSuperAdmin from "src/hooks/useIsShowSuperAdmin";

/* eslint-disable react/display-name */
const withSuperAdminRedirect =
  (Component: ComponentType) => (props: Record<string, any>) => {
    const navigate = useNavigate();

    const isShowSuperAdmin = useIsShowSuperAdmin();

    useEffect(() => {
      if (!isShowSuperAdmin) {
        navigate("/dashboard");
        return;
      }
    }, [isShowSuperAdmin, navigate]);

    return !isShowSuperAdmin ? (
      <Flex h="100vh" justify="center" align="center">
        <Text mr={4}> Redirecting Please Wait...</Text>
        <CenterSpinner />
      </Flex>
    ) : (
      <Component {...props} />
    );
  };

export default withSuperAdminRedirect;
