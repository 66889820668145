import { ComponentType } from "react";
// import ActivitiesFilterContextProvider from "src/contexts/ActivitiesFilterContext";
import ContactFilterContextProvider from "src/contexts/ContactsFilterContext";

/* eslint-disable react/display-name */
const withContactsFilterContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <ContactFilterContextProvider>
        <Component {...props} />
      </ContactFilterContextProvider>
    );
  };

export default withContactsFilterContext;
