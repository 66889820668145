import "./App.css";
import { Provider as ReduxProvider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import Router from "src/components/Router";
import store, { persistedStore } from "src/store";
import theme from "src/styles/theme";
import { PersistGate } from "redux-persist/integration/react";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import CacheBuster from "react-cache-buster";
import AppQueryClient from "./AppQueryClient";

// font-faces
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import "react-datepicker/dist/react-datepicker.css";
import AgreementPopup from "./components/modules/dashboard/AgreementPopup";
import BetaTermsPopup from "./components/modules/dashboard/BetaTermsPopup";
import LoginAsAlert from "./components/layout/LoginAsAlert";
import { useLoggedInAs } from "./store/loggedInAs";
import fetchAppMeta from "./services/data/fetchAppMeta";
import Pendo from "./components/analytics/Pendo";

function LoggedInAsGate({ children }: { children: any }) {
  const { isAuthenticated } = useLoggedInAs();
  const { impersonatedBy } = useLoggedInAs();

  return (
    <>
      {isAuthenticated && impersonatedBy && <LoginAsAlert />}
      {children}
    </>
  );
}

function App() {
  useEffect(() => {
    const gtmId = process.env.GOOGLE_TAG_MANAGER_ID as string;
    TagManager.initialize({ gtmId: "GTM-WNMNGT" }); // temporary hardcoded

    console.log("APP VERSION:", process.env.REACT_APP_VERSION);
  }, []);

  // Store entry point uri for post login navigation
  useEffect(() => {
    let pathname = window.location.pathname;
    if (pathname.includes("oauth/callback")) {
      pathname = "/settings/integrations";
    }
    if (window.location.search) {
      pathname += window.location.search;
    }
    localStorage.setItem("entry-point-uri", pathname);

    fetchAppMeta().then((appMeta) => {
      localStorage.setItem("appVersion", appMeta?.version);
    });
  }, []);

  return (
    <CacheBuster
      currentVersion={process.env.REACT_APP_VERSION}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={"."}
    >
      <ChakraProvider theme={theme}>
        <ReduxProvider store={store}>
          <AppQueryClient>
            <PersistGate loading={null} persistor={persistedStore}>
              <BrowserRouter>
                <LoggedInAsGate>
                  <Router />
                  <Pendo />
                </LoggedInAsGate>
              </BrowserRouter>
              <AgreementPopup />
              <BetaTermsPopup />
            </PersistGate>
          </AppQueryClient>
        </ReduxProvider>
      </ChakraProvider>
    </CacheBuster>
  );
}

export default App;
