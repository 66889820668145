import {
  Flex,
  Icon,
  PopoverTrigger,
  Skeleton,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as InfoCircleIcon } from "../../../../../assets/icons/InfoCircle.svg";
import { ReactComponent as MouseSquare } from "../../../../../assets/icons/MouseSquare.svg";
import { ReactComponent as Settings } from "../../../../../assets/icons/Settings.svg";
import { css } from "@emotion/react";

interface IProps {
  isInitLoading: boolean;
  status: string;
  onStatusSwitch: any;
  currentStatusLabel: string;
  expiresAtText: string | null;
  tooltipContent: any;
}

const StatusMenuWidget = ({
  isInitLoading,
  status,
  onStatusSwitch,
  currentStatusLabel,
  expiresAtText,
  tooltipContent,
}: IProps) => {
  const isEmergencyCalls = status === "Emergency Calls Only";
  const isTakingCalls = status === "Online";
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const getSwitchColor = () => {
    if (isEmergencyCalls) return "#ED8936";
    return isTakingCalls ? "#2abd1d" : "#E80B0B";
  };

  return (
    <Flex mr={2}>
      <Flex
        backgroundColor={"#F9FBFF"}
        border={"1px solid #E3EBFC"}
        borderRadius={"5px"}
        height={"50px"}
        display={"flex"}
        alignItems={"center"}
        padding={"17px"}
        px={{ base: 2, lg: "17px" }}
      >
        <Skeleton isLoaded={!isInitLoading}>
          <Flex align="center">
            <Flex
              css={css`
                .chakra-switch__track {
                  background: ${getSwitchColor()};
                }
              `}
            >
              <Switch
                color="orange.300"
                colorScheme="orange"
                variant={"status"}
                className={"pendo-status-switch"}
                onChange={(e: any) => {
                  onStatusSwitch(e.target.checked ? "Online" : "Offline");
                }}
                isChecked={status === "Online"}
              />
            </Flex>
            <Flex ml={"15px"} flexDirection={"column"} minWidth={"160px"}>
              <Flex>
                <Text
                  fontSize={14}
                  fontWeight={600}
                  className={"pendo-current-status"}
                >
                  {currentStatusLabel}
                </Text>
              </Flex>
              <Flex
                fontStyle={"italic"}
                fontWeight={"400"}
                fontSize={"8px"}
                lineHeight={"11px"}
                color={"#828282"}
                className={"pendo-current-status-expires"}
              >
                {expiresAtText
                  ? `Expires at ${expiresAtText}`
                  : "Does not expire"}
              </Flex>
            </Flex>
            <Flex>
              <Tooltip label={tooltipContent} isOpen={isOpen}>
                <InfoCircleIcon
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                  onClick={onToggle}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Skeleton>
      </Flex>
      <Flex
        ml={{ base: 1, lg: 2 }}
        backgroundColor={"#F9FBFF"}
        border={"1px solid #E3EBFC"}
        borderRadius={"5px"}
        height={"50px"}
        display={"flex"}
        alignItems={"center"}
        padding={"17px"}
      >
        <PopoverTrigger>
          <Flex
            position={"relative"}
            cursor={"pointer"}
            className={"pendo-status-popover"}
          >
            <MouseSquare />
            <Flex position={"absolute"} top={"8px"} left={"8px"}>
              <Settings />
            </Flex>
          </Flex>
        </PopoverTrigger>
      </Flex>
    </Flex>
  );
};

export default StatusMenuWidget;
