import useLogoutMutation from "./mutations/useLogoutMutation";
import useReloginMutation from "./mutations/useReloginMutation";
import { useLoggedInAs } from "../store/loggedInAs";

const useLogout = () => {
  const { isAuthenticated, setIsAuthenticating } = useLoggedInAs();
  const { mutate, isLoading } = useLogoutMutation();
  const { impersonatedBy, setImpersonatedBy } = useLoggedInAs();
  const { mutateAsync: relogin } = useReloginMutation(
    true,
    false,
    "/management/manage-users",
    true
  );

  const handleLogout = async () => {
    if (isAuthenticated && !isLoading && !impersonatedBy) {
      mutate();
    } else if (impersonatedBy) {
      setIsAuthenticating(true);
      await relogin({
        token: impersonatedBy,
      });
      setImpersonatedBy(null);
    }
  };

  return handleLogout;
};

export default useLogout;
