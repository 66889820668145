import { extendTheme } from "@chakra-ui/react";
import breakpoints from "src/constants/breakpoints";
import Button from "./button";
import Input from "./input";
import Select from "./select";
import Switch from "./switch";

const theme = extendTheme({
  fonts: {
    heading: "Montserrat, sans-serif",
    body: "Open Sans, sans-serif",
  },
  colors: {
    brand: {
      panelBg: "#E4ECFC",
      purpleGradient: "linear(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)",
      yellowGradient:
        "linear-gradient(109.5deg, #EFA537 -138.01%, #F8DB68 109.9%)",
      goldGradient:
        "linear-gradient(99.36deg, #EFA537 -39.65%, #F8DB68 71.68%)",
    },
  },
  breakpoints,
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: "#424242",
        },
      },
    },
    Button,
    Input,
    Select,
    Switch,
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export default theme;
