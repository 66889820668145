import fetchSubscriptions from "src/services/data/fetchSubscriptions";
import useDataWithPagination from "src/hooks/queries/paginationQuery/useDataWithPagination";
import getSubscriptionsKeys, {
  SubscriptionsOptionsType,
} from "src/hooks/queries/subscription/getSubscriptionsKeys";
import useUserAccount from "src/store/userAccountState";
import { PaginationQueryType } from "src/types";
import type { PackageType as SubPackageType } from "src/hooks/queries/subscription/useActiveSubscriptionQuery/useActiveSubscription";
import useIsShowSubscriptionTab from "src/hooks/useIsShowSubscriptionTab";

// SAMPLE RESPONSE*
// {
//   price: 95,
//   name: "24/7 Live Coverage",
//   liveReceptionistMinutesInPackage: null,
//   liveChatsInPackage: null,
//   id: "01t31000007HoEAAA0",
//   code: "2270",
// };

// export type SubscriptionPackageType = {
//   price: number;
//   liveReceptionistMinutesInPackage: StringOrNull;
//   liveChatsInPackage: StringOrNull;
//   id: string;
//   code: string;
//   name: string;
// };

export type PackageType = SubPackageType;

export type SubscriptionsDataType = Array<PackageType>;

type SubscriptionsResponseType = {
  data: SubscriptionsDataType;
  pagination: PaginationQueryType;
};

const useGetSubscriptionsQuery = () => {
  const { userAccount } = useUserAccount();
  const businessAccountId = userAccount?.businessAccountId;

  const queryKeys = getSubscriptionsKeys({
    businessAccountId,
  });

  const isShowSubscription = useIsShowSubscriptionTab();

  const enabled = isShowSubscription && !!businessAccountId;

  const queryWithPagination = useDataWithPagination<
    SubscriptionsOptionsType,
    SubscriptionsResponseType
  >(queryKeys, fetchSubscriptions, enabled);

  return queryWithPagination;
};

export default useGetSubscriptionsQuery;
