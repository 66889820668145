import axios from "src/services/network/axios";

import { QueryKeyPaginationType } from "src/hooks/queries/paginationQuery/useDataWithPagination";
import { SubscriptionsOptionsType } from "src/hooks/queries/subscription/getSubscriptionsKeys";

export type FetcherParamType = {
  queryKey: QueryKeyPaginationType<SubscriptionsOptionsType>;
};

const fetchSubscriptions = async ({ queryKey }: FetcherParamType) => {
  const options = queryKey[1];
  const { businessAccountId } = options;

  const response = await axios.get(
    `/businessAccounts/${businessAccountId}/subscriptionPackages?page=1&limit=100&query=&sortType=ASC`
  );

  return response?.data;
};

export default fetchSubscriptions;
