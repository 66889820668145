import { ComponentType } from "react";
import ActivitiesFilterContextProvider from "src/contexts/ActivitiesFilterContext";

/* eslint-disable react/display-name */
const withActivitiesFilterContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <ActivitiesFilterContextProvider>
        <Component {...props} />
      </ActivitiesFilterContextProvider>
    );
  };

export default withActivitiesFilterContext;
