const entityTypes = {
  ACTIVITIES: "activities",
  ACTIVITY_BADGE: "activityBadge",
  ANNOUNCEMENTS: "announcements",
  USERS: "users",
  BETA_FEATURES: "betaFeatures",
  CHANGE_LOGS: "changeLogs",
  CALL_STATUS: "callStatus",
  CALL_STATUS_SCHEDULER: "callStatusScheduler",
  CALL_STATUS_TEMPLATES: "callStatusTemplates",
  INVOICES: "invoices",
  INVOICE_PREVIEW: "invoicePreview",
  EXPORT_INVOICE: "exportInvoice",
  FAQS: "faqs",
  ACTIVE_SUBSCRIPTIONS: "activeSubscriptions",
  SUBSCRIPTION_PLANS: "subscriptionPlans",
  SUBSCRIPTIONS: "subscriptions",
  CONTACTS: "contacts",
  ACTIVITIESCHART: "activitesChart",
  BLOCKEDCONTACTS: "blockedContacts",
  ACCOUNT_SETTING: "accountSetting",
  PAYMENT_METHODS: "paymentMethods",
  ABBY_SUGGESTIONS: "abbySuggestions",
  MANAGEMENT: "management",
  CALL_TAGS: "callTags",
  ABBY_SUGGESTIONS_BADGE: "abbySuggestionsBadge",
  INTEGRATION_SETTINGS: "integrationSettings",
  INTEGRATION_CRITERIA: "integrationCriteria",
} as const;

export default entityTypes;
