import { ComponentType } from "react";
// import ActiveContactContextProvider from "src/contexts/ActiveContactContext";
import SubscriptionContextProvider from "src/contexts/SubscriptionContext";

/* eslint-disable react/display-name */
const withSubscriptionContext =
  (Component: ComponentType) => (props: Record<string, any>) => {
    return (
      <SubscriptionContextProvider>
        <Component {...props} />
      </SubscriptionContextProvider>
    );
  };

export default withSubscriptionContext;
